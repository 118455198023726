import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ImageSlider6Component } from './components/imageSlider6/imageSlider6.component';

@NgModule({
  imports: [CommonModule],
  exports: [ImageSlider6Component],
  declarations: [ImageSlider6Component],
})
export class ImageSlider6Module {}
