<!-- signageandgraphicdesignForFooterpc -->
<!-- footer starts -->
<!-- foootteerrr -->
<footer class="pt-5  foootteerrr mobilefooter hide_in_mobile" style="margin-top: -50px !important;">
    <div class="footer-upper footer_dot_img  pb-4">
        <div class="container-fluid newfooteerr">
            <div class="row ">
                <!-- col-sm-12 mt-4 -->
                <div class="col-md-3  align-self-center">
                    <!-- <img class="footer_dot_img" src="assets/images/image 3 - 7SF - Home Page.png" alt=""> -->
                    <!-- <div class="mt-4"></div> -->
                    <div class="footer-about ">
                        <img class="footerIcon" src="assets/images/Microprint Logo.png" alt="">
                        <!-- width="600" -->
                    </div>
                </div>
                <!-- col-sm-12 mb-4  -->
                <div class="col-md-3" style="padding: 0;">
                    <!-- <div class=""> -->
                    <!-- .row [class*="col-"] -->
                    <p class="black services h3"
                        style="border-bottom: 10px solid rgb(240, 88, 113) !important; color: rgb(23, 24, 27); font-weight: 700; font-size: 33px;">
                        SERVICES
                    </p>
                    <h3 class="black servicesMobile">
                        SERVICES
                    </h3>
                    <div class="d-flex  serviceLineMobile">
                        <div class="ln1" style="border-top: 3px solid rgb(240, 88, 113);"></div>
                        <div class="ln1" style="border-top: 3px solid rgb(238, 238, 95);"></div>
                        <div class="ln1" style="border-top: 3px solid rgb(80, 135, 80);"></div>
                    </div>
                    <!-- <div class="d-flex mt-2 serviceLineMobile">
                            <div class="mobile_style_lines1"></div>
                            <div class="mobile_style_lines2"></div>
                            <div class="mobile_style_lines3"></div>
                        </div> -->
                    <ul class="servicelinks">
                        <p class="h4" style="font-size: 25px;"><b style="color: rgb(27, 41, 71); font-weight: 800;">Graphic Design</b></p>
                        <li><a style="color: rgb(73, 73, 73); font-family: 'regular', sans-serif !important; font-size: 20px;"  [routerLink]="['/graphic-design']" fragment="brandingANDidentity">Branding & Identity</a></li><br>
                        <li><a style="color: rgb(73, 73, 73); font-family: 'regular', sans-serif !important; font-size: 20px;"  [routerLink]="['/graphic-design']" fragment="marketingANDStrategy">Marketing & Strategy</a></li><br>
                        <li><a style="color: rgb(73, 73, 73); font-family: 'regular', sans-serif !important; font-size: 20px;"  [routerLink]="['/graphic-design']" fragment="packagingDesign">Packaging Design</a></li><br>
                        <li><a style="color: rgb(73, 73, 73); font-family: 'regular', sans-serif !important; font-size: 20px;"  [routerLink]="['/graphic-design']" fragment="publicationEnvironment">Publication Collaterals</a></li><br>
                        <li><a style="color: rgb(73, 73, 73); font-family: 'regular', sans-serif !important; font-size: 20px;"  [routerLink]="['/graphic-design']" fragment="environmentalGraphic">Environmental Graphics</a></li><br>
                        <li><a style="color: rgb(73, 73, 73); font-family: 'regular', sans-serif !important; font-size: 20px;"  [routerLink]="['/graphic-design']" fragment="illustrationID">Illustrations</a></li>
                    </ul>
                    <ul class=" servicelinks footDigit">
                        <p class="h4" style="font-size: 25px;"><b style="color: rgb(27, 41, 71); font-weight: 800;">Digital Marketing</b></p>
                        <li><a style="color: rgb(73, 73, 73); font-family: 'regular', sans-serif !important; font-size: 20px;" [routerLink]="['/digital-marketing']" fragment="socialMediaMarketingID">Social Media Marketing</a></li><br>
                        <li><a style="color: rgb(73, 73, 73); font-family: 'regular', sans-serif !important; font-size: 20px;" [routerLink]="['/digital-marketing']" fragment="websitedesignID">Website Design</a></li><br>
                        <li><a style="color: rgb(73, 73, 73); font-family: 'regular', sans-serif !important; font-size: 20px;" [routerLink]="['/digital-marketing']" fragment="seomanagementID">SEO (Search Engine Optimization)</a>
                        </li><br>
                        <li><a style="color: rgb(73, 73, 73); font-family: 'regular', sans-serif !important; font-size: 20px;" [routerLink]="['/digital-marketing']" fragment="googleadsID">Google Ads</a></li>
                    </ul>
                    <ul class="mt-7 servicelinks">
                        <p class="h4" style="font-size: 25px;"><b style="color: rgb(27, 41, 71); font-weight: 800;">Signage & Wayfinding</b></p>
                        <li><a style="color: rgb(73, 73, 73); font-family: 'regular', sans-serif !important; font-size: 20px;" [routerLink]="['/signage-wayfinding']" fragment="channellettersID">Channel Letters</a></li><br>
                        <li><a style="color: rgb(73, 73, 73); font-family: 'regular', sans-serif !important; font-size: 20px;" [routerLink]="['/signage-wayfinding']" fragment="boxsignsID">Box Sign</a></li><br>
                        <li><a style="color: rgb(73, 73, 73); font-family: 'regular', sans-serif !important; font-size: 20px;" [routerLink]="['/signage-wayfinding']" fragment="pylonsignsID">Pylon Sign</a></li><br>
                        <li><a style="color: rgb(73, 73, 73); font-family: 'regular', sans-serif !important; font-size: 20px;" [routerLink]="['/signage-wayfinding']" fragment="neonsignsID">Neon Sign</a></li><br>
                        <li><a style="color: rgb(73, 73, 73); font-family: 'regular', sans-serif !important; font-size: 20px;" [routerLink]="['/signage-wayfinding']" fragment="litsignsID">Lit Sign</a></li><br>
                        <li><a style="color: rgb(73, 73, 73); font-family: 'regular', sans-serif !important; font-size: 20px;" [routerLink]="['/signage-wayfinding']" fragment="wayfindingsignsID">Wayfinding Signage</a></li>
                    </ul>
                    <!-- </div> -->
                </div>

                <!-- col-sm-12 mb-4 -->
                <div class="col-md-3" style="padding: 0;">
                    <!-- <div class=""> -->
                    <h3 class="black hide_in_mobile" style="border-bottom: 10px solid rgb(238, 238, 95) !important;">
                        &nbsp;</h3>
                    <ul class="mt-2 servicelinks">
                        <p class="h4" style="font-size: 25px;"><b style="color: rgb(27, 41, 71); font-weight: 800;">Wide Format Printing</b></p>
                        <li><a style="color: rgb(73, 73, 73); font-family: 'regular', sans-serif !important; font-size: 20px;" [routerLink]="['/print-media']" fragment="wide_format_printing">Floor Graphics</a></li><br>
                        <li><a style="color: rgb(73, 73, 73); font-family: 'regular', sans-serif !important; font-size: 20px;" [routerLink]="['/print-media']" fragment="wide_format_printing">Banners & Posters</a></li><br>
                        <li><a style="color: rgb(73, 73, 73); font-family: 'regular', sans-serif !important; font-size: 20px;" [routerLink]="['/print-media']" fragment="wide_format_printing">Pull-up Banners</a></li><br>
                        <li><a style="color: rgb(73, 73, 73); font-family: 'regular', sans-serif !important; font-size: 20px;" [routerLink]="['/print-media']" fragment="wide_format_printing">Magnet Decals</a></li><br>
                        <li><a style="color: rgb(73, 73, 73); font-family: 'regular', sans-serif !important; font-size: 20px;" [routerLink]="['/print-media']" fragment="wide_format_printing">Window Graphics</a></li><br>
                        <li><a style="color: rgb(73, 73, 73); font-family: 'regular', sans-serif !important; font-size: 20px;" [routerLink]="['/print-media']" fragment="wide_format_printing">Canvas & Textile</a></li><br>
                        <li><a style="color: rgb(73, 73, 73); font-family: 'regular', sans-serif !important; font-size: 20px;" [routerLink]="['/print-media']" fragment="wide_format_printing">Car & Wall Decals</a></li><br>
                        <li><a style="color: rgb(73, 73, 73); font-family: 'regular', sans-serif !important; font-size: 20px;" [routerLink]="['/print-media']" fragment="wide_format_printing">Frosted Films & Wall Vinyls</a></li>
                    </ul>
                    <ul class="mt-2 servicelinks">
                        <p class="h4" style="font-size: 25px;"><b style="color: rgb(27, 41, 71); font-weight: 800;">Signs</b></p>
                        <li><a style="color: rgb(73, 73, 73); font-family: 'regular', sans-serif !important; font-size: 20px;" [routerLink]="['/print-media']" fragment="signsID">Coroplast Signs</a></li>
                        <br>
                        <li><a style="color: rgb(73, 73, 73); font-family: 'regular', sans-serif !important; font-size: 20px;" [routerLink]="['/print-media']" fragment="signsID">Aluminum Signs</a></li><br>
                        <li><a style="color: rgb(73, 73, 73); font-family: 'regular', sans-serif !important; font-size: 20px;" [routerLink]="['/print-media']" fragment="signsID">Posters & Styrene Signs</a></li><br>
                        <li><a style="color: rgb(73, 73, 73); font-family: 'regular', sans-serif !important; font-size: 20px;" [routerLink]="['/print-media']" fragment="signsID">Foam Board Signs</a></li><br>
                        <li><a style="color: rgb(73, 73, 73); font-family: 'regular', sans-serif !important; font-size: 20px;" [routerLink]="['/print-media']" fragment="signsID">Framed Banners & Standees</a></li>
                    </ul>
                    <ul class="mt-2 servicelinks">
                        <p class="h4" style="font-size: 25px;"><b style="color: rgb(27, 41, 71); font-weight: 800;">Print Media</b></p>
                        <li><a style="color: rgb(73, 73, 73); font-family: 'regular', sans-serif !important; font-size: 20px;" [routerLink]="['/graphic-design']" fragment="publicationCollateralsID">Stationary Collaterals</a></li><br>
                        <li><a style="color: rgb(73, 73, 73); font-family: 'regular', sans-serif !important; font-size: 20px;" [routerLink]="['/print-media']" fragment="printMediaID">Presentation Folders</a></li><br>
                        <li><a style="color: rgb(73, 73, 73); font-family: 'regular', sans-serif !important; font-size: 20px;" [routerLink]="['/print-media']" fragment="printMediaID">Greeting Cards</a></li><br>
                        <li><a style="color: rgb(73, 73, 73); font-family: 'regular', sans-serif !important; font-size: 20px;" [routerLink]="['/print-media']" fragment="printMediaID">Tent Cards</a></li><br>
                        <li><a style="color: rgb(73, 73, 73); font-family: 'regular', sans-serif !important; font-size: 20px;" [routerLink]="['/print-media']" fragment="printMediaID">Brochures & Booklets</a></li><br>
                        <li><a style="color: rgb(73, 73, 73); font-family: 'regular', sans-serif !important; font-size: 20px;" [routerLink]="['/print-media']" fragment="printMediaID">Door Hangers</a></li>
                    </ul>
                    <!-- </div> -->
                </div>

                <!-- col-sm-12 mb-4 -->
                <div class="col-md-3" style="padding: 0;">
                    <!-- <div class=""> -->
                    <p class="black services h3"
                        style="border-bottom: 10px solid rgb(39, 137, 58) !important; color: rgb(27, 41, 71); font-weight: 700; font-size: 33px;">
                        CONTACT US
                    </p>
                    <h3 class="black servicesMobile">
                        CONTACT US
                    </h3>
                    <!-- <div class="row d-flex serviceLineMobile">
                            <div class="col-md-4" style="border-top: 3px solid rgb(240, 88, 113);"></div>
                            <div class="col-md-4" style="border-top: 3px solid rgb(238, 238, 95);"></div>
                            <div class="col-md-4" style="border-top: 3px solid rgb(80, 135, 80);"></div>
                        </div> -->

                    <div class="d-flex  serviceLineMobile">
                        <div class="ln1" style="border-top: 3px solid rgb(240, 88, 113);"></div>
                        <div class="ln1" style="border-top: 3px solid rgb(238, 238, 95);"></div>
                        <div class="ln1" style="border-top: 3px solid rgb(80, 135, 80);"></div>
                    </div>
                    <ul class="mt-2 servicelinks">
                        <p class="h4" style="font-size: 25px;"><b style="color: rgb(27, 41, 71); font-weight: 800;">Main Office</b></p>
                        <li><a style="color: rgb(73, 73, 73); font-family: 'regular', sans-serif !important; font-size: 20px;" href="about-us.html"><i style="color: red"
                                    class="fa fa-map-marker"></i> &nbsp;2224 Albert St, Regina,
                                SK S4P 2V2. Saskatchewan, CA</a></li><br>
                        <li><a style="color: black; font-family: 'regular', sans-serif !important; font-size: 20px;" href="about-us.html"><i style="color: yellow"
                                    class="fa fa-phone"></i> &nbsp;+1 (306) 525 8878</a></li><br>
                        <li><a style="color: black; font-family: 'regular', sans-serif !important; font-size: 20px;" href="about-us.html"><i style="color: lightblue"
                                    class="fa fa-inbox"></i> &nbsp;info@microprint.ca</a></li>
                    </ul>
                    <ul class="mt-2 servicelinks">
                        <p class="h4" style="font-size: 25px;"><b style="color: rgb(27, 41, 71); font-weight: 800;">Branch Office</b></p>
                        <li><a style="color: rgb(73, 73, 73); font-family: 'regular', sans-serif !important; font-size: 20px;" href="about-us.html"><i style="color: red"
                                    class="fa fa-map-marker"></i> &nbsp;9353 50 St NW #15, Edmonton,
                                AB T6B 2L5. Alberta, CA</a></li><br>
                        <li><a style="color: black; font-family: 'regular', sans-serif !important;font-size: 20px;" href="about-us.html"><i style="color: yellow"
                                    class="fa fa-phone"></i> &nbsp;+1 (587) 524 9592</a></li><br>
                                    <li><a style="color: black; font-family: 'regular', sans-serif !important; font-size: 20px;" href="about-us.html"><i style="color: lightblue"
                                                class="fa fa-inbox"></i> &nbsp;info@microprint.ca</a></li>
                    </ul>
                    <ul class="mt-2servicelinks">
                        <div class="d-flex ">
                            <div class="p-2">
                                <li class="p-2"><a href="#"><i style="font-size: 35px; color: rgb(181, 104, 117);"
                                            class="fab fa-facebook " aria-hidden="true"></i></a></li>
                                <li class="p-2"><a href="#"><i style="font-size: 35px; color: rgb(181, 104, 117);"
                                            class="fab fa-instagram " aria-hidden="true"></i></a></li>
                            </div>
                            <div class="p-2">
                                <h6 class="h6" style="color: #717171; font-size: 15px;">Follow. like. Share.</h6>
                                <h6 class="h6" style="color: black; font-weight: 600; font-size: 15px;">@microprint.ca</h6>
                            </div>
                        </div>
                    </ul>
                    <ul class="mt-2 servicelinks">
                        <p class="h4" style="font-size: 25px;"><b style="color: rgb(27, 41, 71); font-weight: 800;">Quick Links</b></p>
                        <li><a style="color: rgb(73, 73, 73); font-family: 'regular', sans-serif !important; font-size: 20px;" routerLink="/home">Home</a></li><br>
                        <li><a style="color: rgb(73, 73, 73); font-family: 'regular', sans-serif !important; font-size: 20px;" routerLink="/about">About</a></li><br>
                        <li><a style="color: rgb(73, 73, 73); font-family: 'regular', sans-serif !important; font-size: 20px;" routerLink="/graphic-design">Services</a></li><br>
                        <li><a style="color: rgb(73, 73, 73); font-family: 'regular', sans-serif !important; font-size: 20px;" routerLink="/contact">Contact</a></li>
                    </ul>

                    <!-- </div> -->
                </div>
                <img class="footer_img" src="assets/images/Updated_Images/Asset 1511.png" alt="">
            </div>
        </div>
    </div>
    <!-- neapolitan -->
    <!-- <div class="row">
            <div class="col-md-4" style="border-top: 3px solid rgb(240, 88, 113);"></div>
            <div class="col-md-4" style="border-top: 3px solid rgb(238, 238, 95);"></div>
            <div class="col-md-4" style="border-top: 3px solid rgb(80, 135, 80);"></div>
        </div> -->

    <div class="d-flex  serviceLineMobile11">
        <div class="ln11" style="border-top: 3px solid rgb(240, 88, 113);"></div>
        <div class="ln11" style="border-top: 3px solid rgb(238, 238, 95);"></div>
        <div class="ln11" style="border-top: 3px solid rgb(80, 135, 80);"></div>
    </div>
    <div class="footer-copyright pt-2 pb-2 ">
        <!-- <div class="container"> -->
        <div class="align-items-center">
            <div class="copyright-text footerMobile">
                <p class="m-0 black h5">2024 &copy; Copyright. All rights reserved.</p>
            </div>
        </div>
    </div>
</footer>
<!-- footer ends -->









<footer class=" mobilefooter hide_in_pc" style="margin-top: -20% !important;">
    <div class="footer-upper foootteerrr pb-4">
        <div class="">
            <div class="row">
                <div class="col-md-3 align-self-center col-sm-12 mt-4 mb-4">
                    <!-- <div class="mt-4"></div> -->
                    <div class="footer-about ">
                        <img class="footerIcon" src="assets/images/Microprint Logo.png" alt="">
                        <!-- width="600" -->
                    </div>
                </div>
                <div class="col-md-3 col-sm-12 mb-4 " style="padding: 0;">
                    <!-- <div class=""> -->
                    <!-- .row [class*="col-"] -->
                    <h3 class="black services"
                        style="border-bottom: 10px solid rgb(240, 88, 113) !important; color: rgb(27, 41, 71); font-weight: 700;">
                        SERVICES
                    </h3>
                    <h3 class="black servicesMobile h3">
                        SERVICES
                    </h3>
                    <div class="d-flex  serviceLineMobilecontact">
                        <div class="ln1" style="border-top: 5px solid rgb(240, 88, 113);"></div>
                        <div class="ln1" style="border-top: 5px solid rgb(238, 238, 95);"></div>
                        <div class="ln1" style="border-top: 5px solid rgb(80, 135, 80);"></div>
                    </div>
                    <!-- <div class="d-flex mt-2 serviceLineMobile">
                            <div class="mobile_style_lines1"></div>
                            <div class="mobile_style_lines2"></div>
                            <div class="mobile_style_lines3"></div>
                        </div> -->
                    <ul class="servicelinks">
                        <p class="h4 mobilediscription"><b style="color: rgb(27, 41, 71); font-weight: 800;">Graphic
                                Design</b></p>
                        <li><a style="color: black;"  [routerLink]="['/graphic-design']" fragment="brandingANDidentity" class="h5 mobilediscription">Branding &
                                Identity</a></li><br>
                        <li><a style="color: black;"  [routerLink]="['/graphic-design']" fragment="marketingANDStrategy" class="h5 mobilediscription">Marketing &
                                Strategy</a></li><br>
                        <li><a style="color: black;"  [routerLink]="['/graphic-design']" fragment="packagingDesign" class="h5 mobilediscription">Packaging
                                Design</a></li><br>
                        <li><a style="color: black;" [routerLink]="['/graphic-design']" fragment="publicationEnvironment" class="h5 mobilediscription">Publication
                                Collaterals</a></li><br>
                        <li><a style="color: black;" [routerLink]="['/graphic-design']" fragment="environmentalGraphic" class="h5 mobilediscription">Environmental
                                Graphics</a></li><br>
                        <li><a style="color: black;" [routerLink]="['/graphic-design']" fragment="illustrationID" class="h5 mobilediscription">Illustrations</a>
                        </li>
                    </ul>
                    <ul class="mt-2 servicelinks">
                        <p class="h4 mobilediscription"><b style="color: rgb(27, 41, 71); font-weight: 800;">Difital Marketing</b></p>
                        <li><a style="color: black;" [routerLink]="['/digital-marketing']" fragment="socialMediaMarketingID" class="h5 mobilediscription">Social Media Marketing</a></li><br>
                        <li><a style="color: black;" [routerLink]="['/digital-marketing']" fragment="websitedesignID" class="h5 mobilediscription">Website Design</a></li>
                        <br>
                        <li><a style="color: black;" [routerLink]="['/digital-marketing']" fragment="seomanagementID" class="h5 mobilediscription">SEO (Search Engine Optimization)</a>
                        </li><br>
                        <li><a style="color: black;" [routerLink]="['/digital-marketing']" fragment="googleadsID" class="h5 mobilediscription">Google Ads</a>
                        </li>
                    </ul>
                    <ul class="mt-2 servicelinks">
                        <p class="h4 mobilediscription"><b style="color: rgb(27, 41, 71); font-weight: 800;">Signage & Wayfinding</b></p>
                        <li><a style="color: black;" [routerLink]="['/signage-wayfinding']" fragment="channellettersID" class="h5 mobilediscription">Channel Letters</a></li><br>
                        <li><a style="color: black;" [routerLink]="['/signage-wayfinding']" fragment="boxsignsID" class="h5 mobilediscription">Box Sign</a>
                        </li><br>
                        <li><a style="color: black;" [routerLink]="['/signage-wayfinding']" fragment="pylonsignsID" class="h5 mobilediscription">Pylon Sign</a></li><br>
                        <li><a style="color: black;" [routerLink]="['/signage-wayfinding']" fragment="neonsignsID" class="h5 mobilediscription">Neon Sign</a></li><br>
                        <li><a style="color: black;" [routerLink]="['/signage-wayfinding']" fragment="litsignsID" class="h5 mobilediscription">Lit Sign</a></li><br>
                        <li><a style="color: black;" [routerLink]="['/signage-wayfinding']" fragment="wayfindingsignsID" class="h5 mobilediscription">Wayfinding Signage</a></li>
                    </ul>
                    <!-- </div> -->
                </div>
                <div class="col-md-3 col-sm-12 mb-4" style="padding: 0;">
                    <!-- <div class=""> -->
                    <h3 class="black hide_in_mobile" style="border-bottom: 10px solid rgb(238, 238, 95) !important;">
                        &nbsp;</h3>
                    <ul class="mt-2 servicelinks">
                        <p class="h4 mobilediscription"><b style="color: rgb(27, 41, 71); font-weight: 800;">Wide Format Printing</b></p>
                        <li><a style="color: black;" [routerLink]="['/print-media']" fragment="wide_format_printing" class="h5 mobilediscription">Floor Graphics</a>
                        </li><br>
                        <li><a style="color: black;" [routerLink]="['/print-media']" fragment="wide_format_printing" class="h5 mobilediscription">Banners & Posters</a></li><br>
                        <li><a style="color: black;" [routerLink]="['/print-media']" fragment="wide_format_printing" class="h5 mobilediscription">Pull-up Banners</a></li><br>
                        <li><a style="color: black;" [routerLink]="['/print-media']" fragment="wide_format_printing" class="h5 mobilediscription">Magnet Decals</a>
                        </li><br>
                        <li><a style="color: black;" [routerLink]="['/print-media']" fragment="wide_format_printing" class="h5 mobilediscription">Window Graphics</a></li>
                        <br>
                        <li><a style="color: black;" [routerLink]="['/print-media']" fragment="wide_format_printing" class="h5 mobilediscription">Canvas & Textile</a></li><br>
                        <li><a style="color: black;" [routerLink]="['/print-media']" fragment="wide_format_printing" class="h5 mobilediscription">Car & Wall Decals</a></li><br>
                        <li><a style="color: black;" [routerLink]="['/print-media']" fragment="wide_format_printing" class="h5 mobilediscription">Frosted Films & Wall Vinyls</a></li><br>
                    </ul>
                    <ul class="mt-2 servicelinks">
                        <p class="h4 mobilediscription"><b style="color: rgb(27, 41, 71); font-weight: 800;">Signs</b></p>
                        <li><a style="color: black;" [routerLink]="['/print-media']" fragment="signsID" class="h5 mobilediscription">Coroplast Signs</a></li>
                        <br>
                        <li><a style="color: black;" [routerLink]="['/print-media']" fragment="signsID" class="h5 mobilediscription">Aluminum Signs</a></li><br>
                        <li><a style="color: black;" [routerLink]="['/print-media']" fragment="signsID" class="h5 mobilediscription">Posters & Styrene Signs</a>
                        </li><br>
                        <li><a style="color: black;" [routerLink]="['/print-media']" fragment="signsID" class="h5 mobilediscription">Foam Board Signs</a></li><br>
                        <li><a style="color: black;" [routerLink]="['/print-media']" fragment="signsID" class="h5 mobilediscription">Framed Banners & Standees</a>
                        </li>
                    </ul>
                    <ul class="mt-2 servicelinks">
                        <p class="h4 mobilediscription"><b style="color: rgb(27, 41, 71); font-weight: 800;">Print Media</b></p>
                        <li><a style="color: black;" [routerLink]="['/graphic-design']" fragment="publicationCollateralsID" class="h5 mobilediscription">Stationary Collaterals</a></li>
                        <br>
                        <li><a style="color: black;" [routerLink]="['/print-media']" fragment="printMediaID" class="h5 mobilediscription">Presentation Folders</a></li><br>
                        <li><a style="color: black;" [routerLink]="['/print-media']" fragment="printMediaID" class="h5 mobilediscription">Greeting Cards</a>
                        </li><br>
                        <li><a style="color: black;" [routerLink]="['/print-media']" fragment="printMediaID" class="h5 mobilediscription">Tent Cards</a></li><br>
                        <li><a style="color: black;" [routerLink]="['/print-media']" fragment="printMediaID" class="h5 mobilediscription">Brochures & Booklets</a></li>
                        <br>
                        <li><a style="color: black;" [routerLink]="['/print-media']" fragment="printMediaID" class="h5 mobilediscription">Door Hangers</a>
                        </li>
                    </ul>
                    <!-- </div> -->
                </div>
                <div class="col-md-3 col-sm-12 mb-4" style="padding: 0;">
                    <!-- <div class=""> -->
                    <h3 class="black services"
                        style="border-bottom: 10px solid rgb(39, 137, 58) !important; color: rgb(27, 41, 71); font-weight: 700;">
                        CONTACT US
                    </h3>
                    <h3 class="black servicesMobile h3">
                        CONTACT US
                    </h3>
                    <!-- <div class="row d-flex serviceLineMobile">
                            <div class="col-md-4" style="border-top: 3px solid rgb(240, 88, 113);"></div>
                            <div class="col-md-4" style="border-top: 3px solid rgb(238, 238, 95);"></div>
                            <div class="col-md-4" style="border-top: 3px solid rgb(80, 135, 80);"></div>
                        </div> -->

                    <div class="d-flex  serviceLineMobilecontact">
                        <div class="ln1" style="border-top: 5px solid rgb(240, 88, 113);"></div>
                        <div class="ln1" style="border-top: 5px solid rgb(238, 238, 95);"></div>
                        <div class="ln1" style="border-top: 5px solid rgb(80, 135, 80);"></div>
                    </div>
                    <ul class="mt-2 servicelinks">
                        <p class="h4 mobilediscription"><b style="color: rgb(27, 41, 71); font-weight: 800;">Main
                                Office</b></p>
                        <li class="d-flex"><i style="color: red" class="fa fa-map-marker footer_icns"></i>
                            <a style="color: black;" href="about-us.html" class="h5 mobilediscription">2224
                                Albert St, Regina,
                                SK S4P 2V2. Saskatchewan, CA</a>
                        </li><br>
                        <li class="d-flex"><i style="color: yellow" class="fa fa-phone footer_icns"></i>
                            <a style="color: black;" href="about-us.html" class="h5 mobilediscription">+1 (306) 525
                                8878</a>
                        </li><br>
                        <li class="d-flex"><i style="color: lightblue" class="fa fa-inbox footer_icns"></i>
                            <a style="color: black;" href="about-us.html"
                                class="h5 mobilediscription">info@microprint.ca</a>
                        </li>
                    </ul>
                    <ul class="mt-2 servicelinks">
                        <p class="h4 mobilediscription"><b style="color: rgb(27, 41, 71); font-weight: 800;">Branch
                                Office</b></p>
                        <li class="d-flex"><i style="color: red" class="fa fa-map-marker footer_icns"></i>
                            <a style="color: black;" href="about-us.html" class="h5 mobilediscription">9353 50 St NW
                                #15, Edmonton,
                                AB T6B 2L5. Alberta, CA</a>
                        </li><br>
                        <li class="d-flex"><i style="color: yellow" class="fa fa-phone footer_icns"></i>
                            <a style="color: black;" href="about-us.html" class="h5 mobilediscription">+1 (587) 524
                                9592</a>
                        </li>
                    </ul>
                    <ul class="mt-2 servicelinks">
                        <p class="h4 mobilediscription"><b style="color: rgb(27, 41, 71); font-weight: 800;">Quick
                                Links</b></p>
                        <li><a style="color: black;" routerLink="/home" class="h5 mobilediscription">Home</a></li><br>
                        <li><a style="color: black;" routerLink="/about" class="h5 mobilediscription">About</a></li>
                        <br>
                        <li><a style="color: black;" routerLink="/graphic-design" class="h5 mobilediscription">Services</a></li>
                        <br>
                        <li><a style="color: black;" routerLink="/contact" class="h5 mobilediscription">Contact</a></li>
                    </ul>
                    <ul class="mt-2servicelinks">
                        <div class="d-flex justify-content-center">
                            <div class="p-2">
                                <li><a href="#"><i style="font-size: 45px; color: rgb(181, 104, 117);"
                                            class="fab fa-facebook " aria-hidden="true"></i></a></li>
                                <li><a href="#"><i style="font-size: 45px; color: rgb(181, 104, 117);"
                                            class="fab fa-instagram " aria-hidden="true"></i></a></li>
                            </div>
                            <div class="p-2">
                                <h6 style="color: #717171;" class="h6 mobilediscription">Follow. like. Share.</h6>
                                <h6 style="color: black; font-weight: 600;" class="h6 mobilediscription">@microprint.ca
                                </h6>
                            </div>
                        </div>
                    </ul>
                    <!-- </div> -->
                </div>
            </div>
        </div>
    </div>
    <!-- neapolitan -->
    <!-- <div class="row">
            <div class="col-md-4" style="border-top: 3px solid rgb(240, 88, 113);"></div>
            <div class="col-md-4" style="border-top: 3px solid rgb(238, 238, 95);"></div>
            <div class="col-md-4" style="border-top: 3px solid rgb(80, 135, 80);"></div>
        </div> -->

    <div class="d-flex  serviceLineMobile">
        <div class="ln1" style="border-top: 3px solid rgb(240, 88, 113);"></div>
        <div class="ln1" style="border-top: 3px solid rgb(238, 238, 95);"></div>
        <div class="ln1" style="border-top: 3px solid rgb(80, 135, 80);"></div>
    </div>
    <div class="footer-copyright pt-2 pb-2 ">
        <!-- <div class="container"> -->
        <div class="align-items-center">
            <div class="copyright-text footerMobile">
                <p class="m-0 black h5 mobilediscription">2024 &copy; Copyright. All rights reserved.</p>
            </div>
        </div>
    </div>
</footer>