import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ImageSlider4Component } from './components/imageSlider4/imageSlider4.component';

@NgModule({
  imports: [CommonModule],
  exports: [ImageSlider4Component],
  declarations: [ImageSlider4Component],
})
export class ImageSlider4Module {}
