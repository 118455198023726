<app-header></app-header>




<!-- about-us starts -->

<section class=" hide_in_mobile about-us pb-6">
    <!-- signageandgraphicdesign -->
    <!-- <div class=""> -->
    <!-- <div class=""> -->
    <!-- <div  class=""> -->
    <!-- <div class="home_carousel"> -->
    <image-slider6 class="slider" [slides]="slides"></image-slider6>
    <!-- </div> -->


    <div>
        <img class="center_section" loading="lazy" src="assets/images/Updated_Images/Asset 3001.png" alt="">
    </div>

    <!-- home_page_settings -->

    <!-- home_first_image -->
    <div class="container-fluid ourvision pb-4">
        <!-- backOne -->
        <!-- about-image-box  -->
        <!-- image_overlay -->
        <!-- <div class="  "> -->
        <div class="fsection row d-flex align-items-center justify-content-between">
            <div class="col-lg-5  mb-4">
                <img class="microprint_img" loading="lazy" src="assets/images/Updated_Images/Asset 4424242.png" alt="">
            </div>
            <div class="col-lg-6  pt-4">

                <div class="about-content">
                    <h2 class="m-0 what_we_do wwd heading_fo_mobile2 pb-4"
                        style="color: rgb(27, 41, 71); font-family: 'maermaid' !important;"><b>OUR VISION</b></h2>
                    <p class="border-b mb-2 pb-2   microprint_discription"
                        style="font-family: 'regular', sans-serif !important; color: rgb(106, 103, 103);"> At
                        Microprint, our vision is to revolutionize visual
                        communication through exceptional graphic design,
                        innovative signage, impactful digital marketing, and
                        high-quality print media.
                    </p>

                </div>

                <div class="about-content">
                    <p class="border-b mb-2 pb-2   microprint_discription"
                        style="font-family: 'regular', sans-serif !important; color: rgb(106, 103, 103);"> We aim to
                        inspire and elevate brands by crafting
                        compelling visual experiences that engage audiences, enhance visibility, and drive success.
                    </p>

                </div>
                <div class="about-content">
                    <p class="border-b mb-2 pb-2   microprint_discription"
                        style="font-family: 'regular', sans-serif !important; color: rgb(106, 103, 103);"> By embracing
                        creativity and cutting-edge technology,
                        we strive to be the premier choice for businesses
                        seeking to make a lasting impression.
                    </p>
                </div>
            </div>

            <div class="col-lg-2 col-sm-12">
            </div>
        </div>
        <!-- </div> -->

    </div>
    <!-- </div> -->
    <!-- 
    <div>
        <img loading="lazy" src="assets/images/Updated_Images/Asset 8.png" alt="" class="first_image_between_section">
    </div> -->



    <div>
        <img class="center_section" loading="lazy" src="assets/images/Updated_Images/Asset 3001.png" alt="">
    </div>


    <div class="container-fluid ourvision pb-4">
        <!-- backOne -->
        <!-- about-image-box  -->
        <!-- image_overlay -->
        <!-- <div class="  "> -->
        <div class="fsection row d-flex align-items-center justify-content-between">

            <div class="col-lg-1 ">
            </div>
            <div class="col-lg-5  pt-4">

                <div class="about-content">
                    <h2 class="m-0 what_we_do wwd heading_fo_mobile2 pb-4"
                        style="color: rgb(27, 41, 71); font-family: 'maermaid' !important;"><b>OUR MISSION</b></h2>
                    <p class="border-b mb-2 pb-2   microprint_discription"
                        style="font-family: 'regular', sans-serif !important; color: rgb(106, 103, 103);"> At
                        MicroPrint, our mission is to empower businesses with
                        innovative graphic design, impactful signage, dynamic
                        digital marketing, and high-quality print media.
                    </p>

                </div>

                <div class="about-content">
                    <p class="border-b mb-2 pb-2   microprint_discription"
                        style="font-family: 'regular', sans-serif !important; color: rgb(106, 103, 103);"> We are
                        dedicated to delivering creative solutions and
                        exceptional service, ensuring your brand stands out. Our
                        commitment to excellence in signage and wayfinding
                        helps guide and inspire your audience, creating memorable experiences and driving success.
                    </p>

                </div>
            </div>

            <div class="col-lg-5  mb-4">
                <img class="microprint_img" loading="lazy" src="assets/images/Updated_Images/Asset 525252.png" alt="">
            </div>

            <div class="col-lg-1 ">
            </div>

        </div>
        <!-- </div> -->

    </div>




    <div>
        <img class="center_section" loading="lazy" src="assets/images/Updated_Images/Asset 3001.png" alt="">
    </div>


    <div class="container-fluid contactForm ">
        <div class="d-flex justify-content-center align-items-center">
            <p class="text-center"
                style="padding-left: 2%; padding-right: 2%; font-weight: 600;  font-size: 33px;  background-color: rgb(198, 209, 156);">
                CONNECT WITH US</p>
        </div>
        <div class="d-flex justify-content-center align-items-center">
            <p class="h3" style="font-size: 60px; font-weight: 600; font-family: 'regular', sans-serif !important;">
                WRITE
                A MESSAGE</p>
        </div>
        <form>
            <div class="row">
                <div class="col-md-6 mb-3 main_name">
                    <input type="text" class="form-control name" id="exampleInputEmail1" placeholder="Full Name">
                </div>
                <div class="col-md-6 mb-3 main_email">
                    <input type="email" class="form-control emailaddress" id="exampleInputPassword1"
                        placeholder="Email Address">
                </div>
            </div>
            <div class="row">
                <div class="col-md-6 mb-3 main_phone">
                    <input type="tel" class="form-control phone" id="exampleInputEmail1" placeholder="Phone">
                </div>
                <div class="col-md-6 mb-3 main_subject">
                    <input type="text" class="form-control subject" id="exampleInputPassword1" placeholder="Subject">
                </div>
            </div>
            <div class="mb-3 main_msg">
                <textarea class="form-control message" rows="7" id="exampleInputPassword1"
                    placeholder="Write a Message"></textarea>
            </div>

            <div class="d-flex justify-content-center align-items-center main_div_btn">
                <button type="submit" class="btn btn-primary text-center contact_button">SEND MESSAGE</button>
            </div>
        </form>
    </div>



    <div>
        <img loading="lazy" class="center_section" src="assets/images/Updated_Images/Asset 3001.png" alt="">
    </div>

    <!-- </div> -->
    <!-- </div> -->
</section>
<!-- about-us ends -->




















<section class="hide_in_pc about-us pb-6">
    <!-- <div class=""> -->
        <!-- <div class=""> -->
            <div class="home_carousel">
                <image-slider6 [slides]="slides"></image-slider6>
            </div>

            <div>
                <img loading="lazy" class="center_section_mobile_img_122" src="assets/images/Updated_Images/Asset 3001.png"
                    alt="">
            </div>


            <div>
                <img loading="lazy" class="center_section_mobile_about"
                    src="assets/images/Updated_Images/Asset 4424242.png" alt="">
            </div>
            <div class="container-fluid  pt-5 pb-4" style="margin-top: 0%;">
                <div class="about-image-box mt-6 ">
                    <div class="row d-flex align-items-center justify-content-between">
                        <div class="col-lg-3 col-sm-12 mb-4">
                            <div class="about-content">
                                <p class="border-b mb-2 pb-2  mobilediscription "
                                    style="font-size: 20px; font-family: 'regular', sans-serif !important;">At
                                    Microprint, our vision is to revolutionize visual
                                    communication through exceptional graphic design,
                                    innovative signage, impactful digital marketing, and
                                    high-quality print media.<br>
                                    <br>
                                    We aim to
                        inspire and elevate brands by crafting
                        compelling visual experiences that engage audiences, enhance visibility, and drive success.<br>
                                    <br>
                                    By embracing
                        creativity and cutting-edge technology,
                        we strive to be the premier choice for businesses
                        seeking to make a lasting impression.
                                </p>
                            </div>
                        </div>

                        <div class="col-lg-3 col-sm-12 mb-4">
                            <img loading="lazy" class="hide_in_mobile" style="margin-top: -550px; "
                                src="assets/images/image 2 - 2SS - Home Page.png" alt="" width="300">
                        </div>
                    </div>
                </div>

            </div>

            <div>
                <img loading="lazy" class="center_section_mobile_img" src="assets/images/Updated_Images/Asset 3001.png"
                    alt="">
            </div>
            <!-- assets/images/Updated_Images/Asset 525252.png -->
            <div>
                <img loading="lazy" class="center_section_mobile_about_2"
                    src="assets/images/Updated_Images/Asset 525252.png" alt="">
            </div>
            <div class="container-fluid  pt-5 pb-4" style="margin-top: 0%;">
                <div class="about-image-box mt-6 ">
                    <div class="row d-flex align-items-center justify-content-between">
                        <div class="col-lg-3 col-sm-12 mb-4">
                            <div class="about-content">
                                <p class="border-b mb-2 pb-2  mobilediscription "
                                    style="font-size: 20px; font-family: 'regular', sans-serif !important;">At
                                    MicroPrint, our mission is to empower businesses with
                                    innovative graphic design, impactful signage, dynamic
                                    digital marketing, and high-quality print media.<br>
                                    <br>
                                    We are
                        dedicated to delivering creative solutions and
                        exceptional service, ensuring your brand stands out. Our
                        commitment to excellence in signage and wayfinding
                        helps guide and inspire your audience, creating memorable experiences and driving success.
                                    
                                </p>
                            </div>
                        </div>

                        <div class="col-lg-3 col-sm-12 mb-4">
                            <img loading="lazy" class="hide_in_mobile" style="margin-top: -550px; "
                                src="assets/images/image 2 - 2SS - Home Page.png" alt="" width="300">
                        </div>
                    </div>
                </div>

            </div>
            <div>
                <img loading="lazy" class="center_section_mobile_img" src="assets/images/Updated_Images/Asset 3001.png"
                    alt="">
            </div>


            

    <div class="container-fluid contactForm ">
        <div class="d-flex justify-content-center align-items-center">
            <p class="text-center"
                style="padding-left: 2%; padding-right: 2%; font-weight: 600;  font-size: 23px;  background-color: rgb(198, 209, 156);">
                CONECT WITH US</p>
        </div>
        <div class="d-flex justify-content-center align-items-center">
            <p class="h3" style="font-size: 30px; font-weight: 600; font-family: 'regular', sans-serif !important;">
                WRITE
                A MESSAGE</p>
        </div>
        <form>
            <div class="row">
                <div class="col-md-6 mb-3 main_name">
                    <input type="text" class="form-control name" id="exampleInputEmail1" placeholder="Full Name">
                </div>
                <div class="col-md-6 mb-3 main_email">
                    <input type="email" class="form-control emailaddress" id="exampleInputPassword1"
                        placeholder="Email Address">
                </div>
            </div>
            <div class="row">
                <div class="col-md-6 mb-3 main_phone">
                    <input type="tel" class="form-control phone" id="exampleInputEmail1" placeholder="Phone">
                </div>
                <div class="col-md-6 mb-3 main_subject">
                    <input type="text" class="form-control subject" id="exampleInputPassword1" placeholder="Subject">
                </div>
            </div>
            <div class="mb-3 main_msg">
                <textarea class="form-control message" rows="7" id="exampleInputPassword1"
                    placeholder="Write a Message"></textarea>
            </div>

            <div class="d-flex justify-content-center align-items-center main_div_btn">
                <button type="submit" class="btn btn-primary text-center contact_button">SEND MESSAGE</button>
            </div>
        </form>
    </div>

            <div>
                <img loading="lazy" class="center_section_mobile_img" src="assets/images/Updated_Images/Asset 3001.png"
                    alt="">
            </div>






        <!-- </div> -->
</section>



<app-footer></app-footer>