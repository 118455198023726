<app-header></app-header>

<!-- <section class="banner overflow-hidden">
    <div class="home_carousel" style="width: 100%; height: 450px; margin: 0 auto">
        <image-slider1 [slides]="slides"></image-slider1>
    </div>
</section> -->


<section class=" about-us pb-6 hide_in_mobile">
    <!-- signageandgraphicdesign -->

    <!-- <div class="image_overlay "> -->
    <!-- graphic_design -->
    <!-- <div class="home_carousel"> -->
    <image-slider4 [slides]="slides"></image-slider4>
    <!-- </div> -->
    <!-- <div class=""> -->

            
    <div>
        <img loading="lazy" class="center_section_4" src="assets/images/Updated_Images/Asset 3001.png" alt="">
    </div>

    <!-- backOne_for_service_1 -->
    <div class="container-fluid  mt-8 signage_design_service_one">
        <div class="about-image-box pt-8">
            <div class="row d-flex align-items-center justify-content-between">
                <!-- <div class="col-lg-2"></div> -->
                <div class="col-lg-7 ">
                    <img loading="lazy" class="digital_marketing_1_img" src="assets/images/Updated_Images/Asset 21.png" alt="">
                    <!-- <img src="assets/images/demo_14.png" width="550" alt=""> -->

                </div>
                <div class="col-lg-5 mb-4 mt-3 sig_wayfin_descr">
                    <p class="m-0 "  style=" font-size: 55px; color: rgb(27, 41, 71); font-family: 'mermaid';"><b>DIGITAL MARKETING</b></p>
                    <!-- <div class="d-flex  mt-2">
                                <div style="width: 100px; border-top: solid 7px rgb(211, 80, 158);"></div>
                                <div style="width: 100px; border-top: solid 7px rgb(218, 218, 83);"></div>
                                <div style="width: 100px; border-top: solid 7px rgb(64, 188, 64);"></div>
                            </div> -->

                    <div class="about-image p-3 mt-3 position-relative">
                        <div class="about-content">
                            <p class=" mb-2 pb-2 " style="font-size: 33px; font-family: 'lights' , sans-serif; color: rgb(106, 103, 103);">We at Microprint bring you the whole package,
                                no matter what industry you’re in, we can help
                                you create a mark in your industry by digital
                                marketing opportunities in-order to gain
                                maximum visibility and reach your target
                                audience at the right time and generate
                                unbelievable sales.
                                <br>
                            </p>
                            <p style="font-size: 33px; font-family: 'regular',sans-serif , sans-serif;">Our Digital Marketing solutions offer</p>
                            <ul>
                                <li class="" style="font-size: 30px; font-family: 'regular' , sans-serif; color: black;"><b>• Social Media Marketing</b></li><br>
                                <li class="" style="font-size: 30px; font-family: 'regular' , sans-serif; color: black;"><b>• Website Design</b></li><br>
                                <li class="" style="font-size: 30px; font-family: 'regular' , sans-serif; color: black;"><b>• SEO management</b></li><br>
                                <li class="" style="font-size: 30px; font-family: 'regular' , sans-serif; color: black;"><b>• Google Ads management</b></li>
                            </ul>
                            <img loading="lazy" class="digital_marketing_2_img" src="assets/images/Updated_Images/Asset 3.png" alt="">
                        </div>
                    </div>
                </div>
                <!-- <div class="col-lg-1"></div> -->
            </div>
            <!-- <div class="row" style="margin-top: 5%;">
                <div class="col-md-7">
                    <img class="signange_wayfinding_1_img" src="assets/images/Updated_Images/Asset 2.png" alt="">
                </div>
                <div class="col-md-5">
                    <img class="signange_wayfinding_2_img" src="assets/images/Updated_Images/Asset 4.png" alt="">
                </div>
            </div> -->
        </div>
    </div>

    <div id="socialMediaMarketingID">
        <img loading="lazy" class="center_section" src="assets/images/Updated_Images/Asset 3001.png" alt="">
    </div>

    <div class="container-fluid social_mdia_mrkting_background_wide_format_2 ">
        <div class="row chnl_letters">
            <div class="col-md-6 chnl_descr">
                <p style="font-size: 60px; color: rgb(27, 41, 71); font-family: 'mermaid';"><b>SOCIAL MEDIA MARKETING</b>
                </p>
                <p class="mt-3 chnl" style="font-size: 35px; font-family: 'lights' , sans-serif; color: rgb(106, 103, 103);">Our social media consultants believe that any
                    content that goes up on social media should
                    serve a purpose and not just be a vanity.
                    <br>
                    <br>
                    We specialize in social media management &
                    we have a simpler approach which is
                    thoroughly focussed on achieving results.
                    <br>
                    <br>
                    We come prepared with results that gives you a
                    clear idea on your spends, business generated,
                    leads and more. .
                </p>
            </div>
            <div class="col-md-6 d-flex">
                <img loading="lazy" class="social_media_marketing_1_img" src="assets/images/Updated_Images/Asset 2001.png" alt="">
            </div>
        </div>

    </div>


    <div id="websitedesignID">
        <img loading="lazy" class="center_section_2" src="assets/images/Updated_Images/Asset 3001.png" alt="">
    </div>
    <!-- backOne -->
    <div class="container-fluid  website_design">
        <div class="row box_signs">
            <div class="col-md-7 d-flex">
                <img loading="lazy" class="website_design_2_img" src="assets/images/Updated_Images/Screenshot 2024-05-09 110055.png"
                    alt="">
            </div>
            <div class="col-md-5">
                <p style="margin-top: 3%; font-size: 60px; color: rgb(27, 41, 71); font-family: 'mermaid';"><b>WEBSITE DESIGN</b></p>
                <!-- <div class="d-flex mt-2">
                    <div style="width: 100px; border-top: solid 7px rgb(211, 80, 158);"></div>
                    <div style="width: 100px; border-top: solid 7px rgb(218, 218, 83);"></div>
                    <div style="width: 100px; border-top: solid 7px rgb(64, 188, 64);"></div>
                </div> -->
                <p class="mt-3 webdesc" style="font-size: 33px; font-family: 'lights' , sans-serif; color: rgb(106, 103, 103);">We offer Web-Design services that proves to be
                    a fast, easy, affordable, reliable and responsive
                    approach towards attracting new clients.
                    <br>
                    <br>
                    Our team is 100% client-focussed, be it for a fully
                    functioning complex e-commerce website or a
                    small one pager website. We commit to serve
                    every client with the same enthusiasm before,
                    during and after the web-development process.
                    <br>
                    <br>
                    We believe that website development program
                    is a huge investment and we make your
                    investment appear fruitful over time.
                    <br>
                </p>
            </div>
            <!-- <div class="col-md-5">
                <img src="assets/images/service_2/image 6 - 3SCL - Signage & Wayfinding_2.png" alt="">
            </div> -->
        </div>
    </div>

    
    <div id="seomanagementID">
        <img loading="lazy" class="center_section_3" src="assets/images/Updated_Images/Asset 3001.png" alt="">
    </div>

    <!-- signageandgraphicdesignForFooter -->
    <div class=" social_mdia_mrkting_background222 seo_management">
        <div class="row">
            <!-- <div class="col-md-6">
                <img src="assets/images/demo_19.png" alt="">
            </div> -->
            <div class="col-md-5 pylon_decr">
                <p style="margin-top: 10%; font-size: 60px; color: rgb(27, 41, 71); font-family: 'mermaid';"><b>SEO MANAGEMENT</b></p>
                <!-- <div class="d-flex mt-2">
                    <div style="width: 100px; border-top: solid 7px rgb(211, 80, 158);"></div>
                    <div style="width: 100px; border-top: solid 7px rgb(218, 218, 83);"></div>
                    <div style="width: 100px; border-top: solid 7px rgb(64, 188, 64);"></div>
                </div> -->
                <p class="mt-3 " style="font-size: 33px; font-family: 'lights' , sans-serif; color: rgb(106, 103, 103);">Search Engine Optimization is an essential part
                    of the digital world which generates maximum
                    leads just by being in the top list of google
                    searches.
                    <br>
                    <br>
                    95% of users use a search engine & with
                    Googleconstantly updating their algorithms, it
                    becomes difficult to stay at the top of the list.
                    <br>
                    <br>
                    We have tools and resources that can help us
                    change the numbers and put you on the top
                    of the search list, thus helping you
                    generate business.
                </p>
            </div>
            <div class="col-md-7 d-flex">
                <img loading="lazy" class="seo_management_1_img" src="assets/images/Updated_Images/Screenshot 2024-05-09 110753.png"
                    alt="">

            </div>
        </div>
    </div>

    <div id="googleadsID">
        <img loading="lazy" class="center_section_4" src="assets/images/Updated_Images/Asset 3001.png" alt="">
    </div>


    <!-- backOne -->
    <div class=" google_ads_management">
        <div class="row">
            <div class="col-md-7">
                <img loading="lazy" class="google_ads_management_1_img"
                    src="assets/images/Updated_Images/Screenshot 2024-05-09 114607.png" alt="">
            </div>
            <div class="col-md-5 google_ads_descr">
                <p style="margin-top: 10%; font-size: 60px; color: rgb(27, 41, 71); font-family: 'mermaid';"><b>GOOGLE ADS MANAGEMENT</b></p>
                <!-- <div class="d-flex mt-2">
                    <div style="width: 100px; border-top: solid 7px rgb(211, 80, 158);"></div>
                    <div style="width: 100px; border-top: solid 7px rgb(218, 218, 83);"></div>
                    <div style="width: 100px; border-top: solid 7px rgb(64, 188, 64);"></div>
                </div> -->
                <p class="mt-3 " style="font-size: 33px; font-family: 'lights' , sans-serif; color: rgb(106, 103, 103);">We have exceptional services to offer for the
                    growth of your business by creating targeted
                    Google Ads campaigns. This ensures that the
                    companies are visible on the web and get
                    maximum reach and visibility.
                    <br>
                    <br>
                    With our talented team on board and lot of
                    experience in managing Google Ads Campaign,
                    we naturally guarantee that our clients shall get
                    the best possible results that not only generate
                    revenue, but bring brand awareness and
                    provide best possible results.
                    <br>
                </p>
                <img loading="lazy" class="google_ads_management_2_img" src="assets/images/Updated_Images/Asset 151.png" alt="">
            </div>
            <!-- <div class="col-md-5">
                <img src="assets/images/demo_21.png" alt="">
            </div> -->
        </div>
    </div>


    
    <div>
        <img loading="lazy" class="center_section_42" src="assets/images/Updated_Images/Asset 3001.png" alt="">
    </div>

    <!-- </div> -->
    <!-- </div> -->
</section>












<section class="hide_in_pc about-us pb-6">
    <div class="home_carousel">
        <image-slider4 [slides]="slides"></image-slider4>
    </div>
    
    <div>
        <img loading="lazy" class="center_section_mobile" src="assets/images/Updated_Images/Asset 3001.png" alt="">
    </div>

    <div class="" style="margin-top: 5% !important;">
        <div class="">

            <div class="container mt-4">
                <div class="about-image-box">
                    <div class="row d-flex align-items-center justify-content-between">
                        <div class="col-lg-7 col-sm-12 mb-4 hide_in_mobile">
                            <img loading="lazy" src="assets/images/demo_14.png" alt="">
                        </div>
                        <div class="col-lg-4 col-sm-12  mt-3">
                            <p class="m-0 "  style=" font-size: 30px; color: black; font-family: 'mermaid';"><b>DIGITAL MARKETING</b></p>
                            <!-- <div class="d-flex  mt-2">
                                <div style="width: 100px; border-top: solid 7px rgb(211, 80, 158);"></div>
                                <div style="width: 100px; border-top: solid 7px rgb(218, 218, 83);"></div>
                                <div style="width: 100px; border-top: solid 7px rgb(64, 188, 64);"></div>
                            </div> -->

                            <div class="about-image p-3 mt-3 box-shadow position-relative">
                                <div class="about-content">
                                    <p class=" mb-2 pb-2  mobilediscription" style="font-size: 20px; font-family: 'lights' , sans-serif;">We at Microprint bring you the whole package,
                                        no matter what industry you’re in, we can help
                                        you create a mark in your industry by digital
                                        marketing opportunities in-order to gain
                                        maximum visibility and reach your target
                                        audience at the right time and generate
                                        unbelievable sales.
                                        <br>
                                    </p>
                                    <p style="font-size: 30px; font-family: 'regular' , sans-serif;">Our Digital Marketing solutions offer</p>
                                    <ul>
                                        <li class=" mobilediscription" style="font-size: 20px; font-family: 'regular' , sans-serif; color: black;"><b>• Social Media Marketing</b></li><br>
                                        <li class=" mobilediscription" style="font-size: 20px; font-family: 'regular' , sans-serif; color: black;"><b>• Website Design</b></li><br>
                                        <li class=" mobilediscription" style="font-size: 20px; font-family: 'regular' , sans-serif; color: black;"><b>• SEO management</b></li><br>
                                        <li class=" mobilediscription" style="font-size: 20px; font-family: 'regular' , sans-serif; color: black;"><b>• Google Ads management</b></li>
                                    </ul>
                                    <img loading="lazy" class="" src="assets/images/Updated_Images/Asset 3.png" alt="">

                                </div>
                            </div>
                        </div>
                        <div class="col-lg-1"></div>
                    </div>
                </div>
            </div>

            <div>
                <img loading="lazy" class="center_section_mobile_img" src="assets/images/Updated_Images/Asset 3001.png" alt="">
            </div>

            <div class="mt-5">
                <div class="row">
                    <div class="col-md-6 hide_in_mobile">
                        <img loading="lazy" src="assets/images/demo_15.png" alt="">
                    </div>
                    <div class="col-md-3 mobie_marketing_strategy">
                        <p class="" style="margin-top: 0px;  font-size: 30px; color: black; font-family: 'mermaid';"><b>Social Media Marketing</b>
                        </p>
                        <!-- <div class="d-flex mt-2">
                            <div style="width: 100px; border-top: solid 7px rgb(211, 80, 158);"></div>
                            <div style="width: 100px; border-top: solid 7px rgb(218, 218, 83);"></div>
                            <div style="width: 100px; border-top: solid 7px rgb(64, 188, 64);"></div>
                        </div> -->
                        <p class="mt-3  mobilediscription" style="font-size: 20px; font-family: 'lights' , sans-serif;">Our social media consultants believe that any
                            content that goes up on social media should
                            serve a purpose and not just be a vanity.
                            <br>
                            <br>
                            We specialize in social media management &
                            we have a simpler approach which is
                            thoroughly focussed on achieving results.
                            <br>
                            <br>
                            We come prepared with results that gives you a
                            clear idea on your spends, business generated,
                            leads and more. .
                        </p>
                        <img loading="lazy" class="" src="assets/images/Updated_Images/Asset 511.png" alt="">

                    </div>
                    <div class="col-md-3 hide_in_mobile">
                        <img class="p-3" loading="lazy" src="assets/images/demo_16.png" alt="">
                    </div>
                </div>

            </div>

            
    <div>
        <img loading="lazy" class="center_section_mobile_img" src="assets/images/Updated_Images/Asset 3001.png" alt="">
    </div>

            <div class="">
                <div class="row">
                    <div class="col-md-3 hide_in_mobile">
                        <img loading="lazy" src="assets/images/demo_18.png" alt="">
                    </div>
                    <div class="col-md-3 mobie_marketing_strategy">
                        <p class="" style="margin-top: 50px;  font-size: 30px; color: black; font-family: 'mermaid';"><b>Website Design</b></p>
                        <!-- <div class="d-flex mt-2">
                            <div style="width: 100px; border-top: solid 7px rgb(211, 80, 158);"></div>
                            <div style="width: 100px; border-top: solid 7px rgb(218, 218, 83);"></div>
                            <div style="width: 100px; border-top: solid 7px rgb(64, 188, 64);"></div>
                        </div> -->
                        <p class="mt-3 mobilediscription" style="font-size: 20px; font-family: 'lights' , sans-serif;">We offer Web-Design services that proves to be
                            a fast, easy, affordable, reliable and responsive
                            approach towards attracting new clients.
                            <br>
                            <br>
                            Our team is 100% client-focussed, be it for a fully
                            functioning complex e-commerce website or a
                            small one pager website. We commit to serve
                            every client with the same enthusiasm before,
                            during and after the web-development process.
                            <br>
                            <br>
                            We believe that website development program
                            is a huge investment and we make your
                            investment appear fruitful over time.
                            <br>
                        </p>
                        <img loading="lazy" src="assets/images/Updated_Images/Asset 131.png" alt="">
                    </div>
                    <div class="col-md-6 hide_in_mobile">
                        <img loading="lazy" src="assets/images/demo_17.png" alt="">
                    </div>
                </div>
            </div>

            
    <div>
        <img loading="lazy" class="center_section_mobile_img" src="assets/images/Updated_Images/Asset 3001.png" alt="">
    </div>

            <div class="">
                <div class="row">
                    <div class="col-md-6 hide_in_mobile">
                        <img loading="lazy" src="assets/images/demo_19.png" alt="">
                    </div>
                    <div class="col-md-3 mobie_marketing_strategy">
                        <p class="" style="margin-top: 50px;  font-size: 30px; color: black; font-family: 'mermaid';"><b>SEO MANAGEMENT</b></p>
                        <!-- <div class="d-flex mt-2">
                            <div style="width: 100px; border-top: solid 7px rgb(211, 80, 158);"></div>
                            <div style="width: 100px; border-top: solid 7px rgb(218, 218, 83);"></div>
                            <div style="width: 100px; border-top: solid 7px rgb(64, 188, 64);"></div>
                        </div> -->
                        <p class="mt-3 mobilediscription" style="font-size: 20px; font-family: 'lights' , sans-serif;">Search Engine Optimization is an essential part
                            of the digital world which generates maximum
                            leads just by being in the top list of google
                            searches.
                            <br>
                            <br>
                            95% of users use a search engine & with
                            Googleconstantly updating their algorithms, it
                            becomes difficult to stay at the top of the list.
                            <br>
                            <br>
                            We have tools and resources that can help us
                            change the numbers and put you on the top
                            of the search list, thus helping you
                            generate business.
                        </p>
                        <img loading="lazy" src="assets/images/Updated_Images/Asset 4144.png" alt="">
                    </div>
                    <div class="col-md-3 hide_in_mobile">
                        <img loading="lazy" src="assets/images/demo_20.png" alt="">
                    </div>
                </div>
            </div>

            
    <div>
        <img loading="lazy" class="center_section_mobile_img" src="assets/images/Updated_Images/Asset 3001.png" alt="">
    </div>

            <div class="">
                <div class="row">
                    <div class="col-md-3 hide_in_mobile">
                        <img loading="lazy" src="assets/images/demo_22.png" alt="">
                    </div>
                    <div class="col-md-3 mobie_marketing_strategy">
                        <p class="" style="margin-top: 50px; font-size: 30px; color: black; font-family: 'mermaid';"><b>GOOGLE ADS MANAGEMENT</b></p>
                        <!-- <div class="d-flex mt-2">
                            <div style="width: 100px; border-top: solid 7px rgb(211, 80, 158);"></div>
                            <div style="width: 100px; border-top: solid 7px rgb(218, 218, 83);"></div>
                            <div style="width: 100px; border-top: solid 7px rgb(64, 188, 64);"></div>
                        </div> -->
                        <p class="mt-3 mobilediscription" style="font-size: 20px; font-family: 'lights' , sans-serif;">We have exceptional services to offer for the
                            growth of your business by creating targeted
                            Google Ads campaigns. This ensures that the
                            companies are visible on the web and get
                            maximum reach and visibility.
                            <br>
                            <br>
                            With our talented team on board and lot of
                            experience in managing Google Ads Campaign,
                            we naturally guarantee that our clients shall get
                            the best possible results that not only generate
                            revenue, but bring brand awareness and
                            provide best possible results.
                            <br>
                        </p>
                        <img loading="lazy" src="assets/images/Updated_Images/Asset 1888.png" alt="">
                    </div>
                    <div class="col-md-6 hide_in_mobile">
                        <img loading="lazy" src="assets/images/demo_21.png" alt="">
                    </div>
                </div>
            </div>


            <div>
                <img loading="lazy" class="center_section_mobile_img" src="assets/images/Updated_Images/Asset 3001.png" alt="">
            </div>

            



            
        </div>
    </div>
</section>




<app-footer></app-footer>