import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ImageSlider5Component } from './components/imageSlider5/imageSlider5.component';

@NgModule({
  imports: [CommonModule],
  exports: [ImageSlider5Component],
  declarations: [ImageSlider5Component],
})
export class ImageSlider5Module {}
