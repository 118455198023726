import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ImageSlider2Component } from './components/imageSlider2/imageSlider2.component';

@NgModule({
  imports: [CommonModule],
  exports: [ImageSlider2Component],
  declarations: [ImageSlider2Component],
})
export class ImageSlider2Module {}
