import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ImageSlider3Component } from './components/imageSlider3/imageSlider3.component';

@NgModule({
  imports: [CommonModule],
  exports: [ImageSlider3Component],
  declarations: [ImageSlider3Component],
})
export class ImageSlidersmall3Module {}
