<app-header></app-header>

<!-- <section class="banner overflow-hidden">
    <div class="home_carousel" style="width: 100%; height: 450px; margin: 0 auto">
        <image-slider1 [slides]="slides"></image-slider1>
    </div>
</section> -->


<section class=" about-us pb-6 hide_in_mobile">
    <!-- signageandgraphicdesign -->

    <!-- <div class="image_overlay "> -->
    <!-- graphic_design -->
    <!-- <div class="home_carousel"> -->
    <image-slider5 [slides]="slides"></image-slider5>
    <!-- </div> -->
    <!-- <div class=""> -->

        <div>
            <img loading="lazy" class="center_section" src="assets/images/Updated_Images/Asset 3001.png" alt="">
        </div>

    <!-- backOne_for_service_1 -->
    <div class="container-fluid  mt-8 signage_design_service_one">
        <div class="about-image-box pt-8">
            <div class="row d-flex align-items-center justify-content-between">
                <!-- <div class="col-lg-2"></div> -->
                <div class="col-lg-7 ">
                    <img loading="lazy" class="digital_marketing_1_img" src="assets/images/Updated_Images/Asset 111.png" alt="">
                    <!-- <img src="assets/images/demo_14.png" width="550" alt=""> -->

                </div>
                <div class="col-lg-5 mb-4 mt-3 ">
                    <p class="m-0 "  style=" font-size: 60px; color: black; font-family: 'mermaid';"><b>PRINT SOLUTIONS
                        </b></p>
                    <!-- <div class="d-flex  mt-2">
                                <div style="width: 100px; border-top: solid 7px rgb(211, 80, 158);"></div>
                                <div style="width: 100px; border-top: solid 7px rgb(218, 218, 83);"></div>
                                <div style="width: 100px; border-top: solid 7px rgb(64, 188, 64);"></div>
                            </div> -->

                    <div class="about-image p-3 mt-3 position-relative">
                        <div class="about-content">
                            <p class=" mb-2 pb-2 sig_wayfin_descr"  style="font-size: 33px; font-family: 'lights' , sans-serif; color: rgb(106, 103, 103);">We at Microprint provide exceptional printing
                                solutions that follow the latest trends, industry
                                updates & creative alternative solutions for your
                                business needs.
                                <br>
                                <br>
                                Be it bulk printing or a custom request, our
                                products add value & quality to your business
                                while maintaining competitive pricing.
                                Our printing solutions expand to -
                            </p>
                            <ul>
                                <li class="" style="font-size: 30px; font-family: 'regular' , sans-serif; color: black"><b>• Wide Format Printing</b></li><br>
                                <li class="" style="font-size: 30px; font-family: 'regular' , sans-serif; color: black"><b>• Signs</b></li><br>
                                <li class="" style="font-size: 30px; font-family: 'regular' , sans-serif; color: black"><b>• Print Media</b></li>
                            </ul>
                            <img loading="lazy" class="digital_marketing_2_img" src="assets/images/Updated_Images/Asset 212.png"
                                alt="">
                        </div>
                    </div>
                </div>
                <!-- <div class="col-lg-1"></div> -->
            </div>
            <!-- <div class="row" style="margin-top: 5%;">
                <div class="col-md-7">
                    <img class="signange_wayfinding_1_img" src="assets/images/Updated_Images/Asset 2.png" alt="">
                </div>
                <div class="col-md-5">
                    <img class="signange_wayfinding_2_img" src="assets/images/Updated_Images/Asset 4.png" alt="">
                </div>
            </div> -->
        </div>
    </div>

    <div>
        <img loading="lazy" class="center_section" src="assets/images/Updated_Images/Asset 3001.png" alt="" id="wide_format_printing">
    </div>
    

    <!-- signageandgraphicdesignForFooter -->

    <!-- wide_format_printing -->
    <div class="container-fluid social_mdia_mrkting_background_wide_format  " id="wide_format_printing">
        <div class="row wide_format_printing2">
            <div class="col-md-1">
                <img loading="lazy" class="wide_format_iimmgg_1" src="assets/images/image 6 - 6SSC - Home Page.png" alt="">

            </div>
            <div class="col-md-5 mb-4 chnl_descr">
                <p style="margin-top: 10%; font-size: 60px; color: black; font-family: 'mermaid';"><b>WIDE FORMAT PRINTING
                    </b>
                </p>
                <p class="mt-3 widedesc" style="font-size: 33px; font-family: 'lights' , sans-serif; color: rgb(106, 103, 103);">We offer wide-format printing solutions,
                    utilizing the latest technology that produces
                    brilliant colors and amazing image quality with
                    scratch, smudge and water resistance.
                    <br>
                    <br>
                    Our wide-format printing solution provides
                    substrates such as
                </p>
                <div class="d-flex wideuls">
                    <ul class="">
                        <li class="" style="font-size: 30px; font-family: 'regular' , sans-serif; color: black"><b>• Floor Graphics</b></li><br>
                        <li class="" style="font-size: 30px; font-family: 'regular' , sans-serif; color: black"><b>• Banners & Posters</b></li><br>
                        <li class="" style="font-size: 30px; font-family: 'regular' , sans-serif; color: black"><b>• Pull Up Banners</b></li><br>
                        <li class="" style="font-size: 30px; font-family: 'regular' , sans-serif; color: black"><b>• Magnet Decals</b></li><br>
                        <li class="" style="font-size: 30px; font-family: 'regular' , sans-serif; color: black"><b>• Window Graphics</b></li>
                    </ul>
                    <ul class="">
                        <li class="" style="font-size: 30px; font-family: 'regular' , sans-serif; color: black"><b>• Canvas</b></li><br>
                        <li class="" style="font-size: 30px; font-family: 'regular' , sans-serif; color: black"><b>• Car & Wall decals</b></li><br>
                        <li class="" style="font-size: 30px; font-family: 'regular' , sans-serif; color: black"><b>• Frosted Films</b></li><br>
                        <li class="" style="font-size: 30px; font-family: 'regular' , sans-serif; color: black"><b>• Wall Vinyls</b></li><br>
                        <li class="" style="font-size: 30px; font-family: 'regular' , sans-serif; color: black"><b>• Textile</b></li>
                    </ul>
                </div>

            </div>
            <div class="col-md-6 d-flex">
                <img loading="lazy" class="wide_format_printing_1_img" src="assets/images/Updated_Images/Asset 414.png" alt="">
                <img loading="lazy" class="wide_format_printing_2_img" src="assets/images/Updated_Images/Asset 515.png" alt="">
            </div>
        </div>

    </div>

    
    <div>
        <img loading="lazy" class="center_section" src="assets/images/Updated_Images/Asset 3001.png" alt="" id="signsID">
    </div>

    <!-- backOne -->
    <div class="container-fluid  ">
        <div class="row signs">
            <div class="col-md-7 d-flex">
                <img loading="lazy" class="signs_2_img" src="assets/images/Updated_Images/Screenshot 2024-05-09 140401.png" alt="">
            </div>
            <div class="col-md-5 signs_descr">
                <h1 style="margin-top: 10%; font-size: 60px; color: black; font-family: 'mermaid';"><b>SIGNS</b></h1>
                <!-- <div class="d-flex mt-2">
                    <div style="width: 100px; border-top: solid 7px rgb(211, 80, 158);"></div>
                    <div style="width: 100px; border-top: solid 7px rgb(218, 218, 83);"></div>
                    <div style="width: 100px; border-top: solid 7px rgb(64, 188, 64);"></div>
                </div> -->
                <p class="mt-3 " style="font-size: 33px; font-family: 'lights' , sans-serif; color: rgb(106, 103, 103);">Most of the business prefer printed signs
                    are attractive and one of the most effective
                    ways to improve brand presence.
                    <br>
                    <br>
                    Printed signs have a huge customizable option
                    with respect to size, shape, style or graphic, and
                    signs with printed, painted, or embossed faces.
                    <br>
                </p>
                <ul class="">
                    <li class="" style="font-size: 30px; font-family: 'regular' , sans-serif; color: black;"><b>• Coroplast Signs</b></li><br>
                    <li class="" style="font-size: 30px; font-family: 'regular' , sans-serif; color: black;"><b>• Aluminum Signs</b></li><br>
                    <li class="" style="font-size: 30px; font-family: 'regular' , sans-serif; color: black;"><b>• Posters & Styrene Signs</b></li><br>
                    <li class="" style="font-size: 30px; font-family: 'regular' , sans-serif; color: black;"><b>• Foam Board Signs</b></li><br>
                    <li class="" style="font-size: 30px; font-family: 'regular' , sans-serif; color: black;"><b>• Framed Banners & Standees</b></li>
                </ul>
            </div>
            <!-- <div class="col-md-5">
                <img src="assets/images/service_2/image 6 - 3SCL - Signage & Wayfinding_2.png" alt="">
            </div> -->
        </div>
    </div>


    <div>
        <img loading="lazy" class="center_section_1" src="assets/images/Updated_Images/Asset 3001.png" alt="" id="printMediaID">
    </div>

    <!-- signageandgraphicdesignForFooter -->
    <!-- seo_management -->
    <div class="print_media_background ">
        <div class="row print_media_main">
            <!-- <div class="col-md-6">
                <img src="assets/images/demo_19.png" alt="">
            </div> -->
            <div class="col-md-5 print_media">
                <h1 style="margin-top: 10%; font-size: 60px; color: black; font-family: 'mermaid';"><b>PRINT MEDIA
                    </b></h1>
                <!-- <div class="d-flex mt-2">
                    <div style="width: 100px; border-top: solid 7px rgb(211, 80, 158);"></div>
                    <div style="width: 100px; border-top: solid 7px rgb(218, 218, 83);"></div>
                    <div style="width: 100px; border-top: solid 7px rgb(64, 188, 64);"></div>
                </div> -->
                <p class="mt-3 " style="font-size: 33px; font-family: 'lights' , sans-serif; color: rgb(106, 103, 103);">When it comes to business communications
                    services, we can do it all. With a complete range
                    of products and services at your disposal,
                    we can help you get your message out to your
                    customers, employees and vendors a like.
                    <br>
                </p>
                <div class="d-flex">
                    <ul class="">
                        <li class="" style="font-size: 30px; font-family: 'regular' , sans-serif; color: black"><b>• Business Cards</b></li><br>
                        <li class="" style="font-size: 30px; font-family: 'regular' , sans-serif; color: black"><b>• Letter Heads</b></li><br>
                        <li class="" style="font-size: 30px; font-family: 'regular' , sans-serif; color: black"><b>• Envelopes</b></li><br>
                        <li class="" style="font-size: 30px; font-family: 'regular' , sans-serif; color: black"><b>• Post Cards</b></li><br>
                        <li class="" style="font-size: 30px; font-family: 'regular' , sans-serif; color: black"><b>• Presentation folders</b></li><br>
                        <li class="" style="font-size: 30px; font-family: 'regular' , sans-serif; color: black"><b>• Flyers</b></li><br>
                        <li class="" style="font-size: 30px; font-family: 'regular' , sans-serif; color: black"><b>• Brochures</b></li>
                    </ul>
                    <ul class="">
                        <li class="" style="font-size: 30px; font-family: 'regular' , sans-serif; color: black"><b>• Bookmarks</b></li><br>
                        <li class="" style="font-size: 30px; font-family: 'regular' , sans-serif; color: black"><b>• Booklets</b></li><br>
                        <li class="" style="font-size: 30px; font-family: 'regular' , sans-serif; color: black"><b>• Greeting Cards</b></li><br>
                        <li class="" style="font-size: 30px; font-family: 'regular' , sans-serif; color: black"><b>• Door Hangers</b></li><br>
                        <li class="" style="font-size: 30px; font-family: 'regular' , sans-serif; color: black"><b>• Tent Cards</b></li>
                    </ul>
                </div>
            </div>
            <div class="col-md-7 d-flex">
                <img loading="lazy" class="seo_management_1_img" src="assets/images/Updated_Images/Asset 2221.png"
                    alt="">

            </div>
        </div>
    </div>

    <div>
        <img loading="lazy" class="center_section_2" src="assets/images/Updated_Images/Asset 3001.png" alt="">
    </div>



    <!-- </div> -->
    <!-- </div> -->
</section>












<section class="hide_in_pc about-us pb-6">
    <div class="home_carousel">
        <image-slider5 [slides]="slides"></image-slider5>
    </div>

    
    <div>
        <img loading="lazy" class="center_section_2_mobile" src="assets/images/Updated_Images/Asset 3001.png" alt="">
    </div>


    <div class="" style="margin-top:0% !important;">
        <div class="">

            <div class="container mt-4">
                <div class="about-image-box">
                    <div class="row d-flex align-items-center justify-content-between">
                        <div class="col-lg-7 col-sm-12 mb-4 hide_in_mobile">
                            <img loading="lazy" src="assets/images/demo_14.png" alt="">
                        </div>
                        <div class="col-lg-4 col-sm-12  mt-3">
                            <p class="m-0 " style=" font-size: 30px; color: black; font-family: 'mermaid';"><b>PRINT SOLUTIONS</b></p>
                            <!-- <div class="d-flex  mt-2">
                                <div style="width: 100px; border-top: solid 7px rgb(211, 80, 158);"></div>
                                <div style="width: 100px; border-top: solid 7px rgb(218, 218, 83);"></div>
                                <div style="width: 100px; border-top: solid 7px rgb(64, 188, 64);"></div>
                            </div> -->

                            <div class="about-image p-3 mt-3 box-shadow position-relative">
                                <div class="about-content">
                                    <p class=" mb-2 pb-2 mobilediscription" style="font-size: 20px; font-family: 'lights' , sans-serif;">We at Microprint provide exceptional printing
                                        solutions that follow the latest trends, industry
                                        updates & creative alternative solutions for your
                                        business needs.
                                        <br>
                                        <br>
                                        Be it bulk printing or a custom request, our
                                        products add value & quality to your business
                                        while maintaining competitive pricing.
                                        Our printing solutions expand to -
                                    </p>
                                    <ul>
                                        <li class=" mobilediscription" style="font-size: 20px; font-family: 'regular' , sans-serif; color: black"><b>• Wide Format Printing</b></li><br>
                                        <li class=" mobilediscription" style="font-size: 20px; font-family: 'regular' , sans-serif; color: black"><b>• Signs</b></li><br>
                                        <li class=" mobilediscription" style="font-size: 20px; font-family: 'regular' , sans-serif; color: black"><b>• Print Media</b></li>
                                    </ul>
                                    <img loading="lazy" class="" src="assets/images/Updated_Images/Asset 111.png" alt="">

                                </div>
                            </div>
                        </div>
                        <div class="col-lg-1"></div>
                    </div>
                </div>
            </div>

            
    <div>
        <img loading="lazy" class="center_section_img_mobile" src="assets/images/Updated_Images/Asset 3001.png" alt="">
    </div>

            <div class="">
                <div class="row">
                    <div class="col-md-6 hide_in_mobile">
                        <img loading="lazy" src="assets/images/demo_15.png" alt="">
                    </div>
                    <div class="col-md-3 mobie_marketing_strategy">
                        <p class="" style="margin-top: 50px;  font-size: 30px; color: black; font-family: 'mermaid';"><b>WIDE FORMAT PRINTING</b>
                        </p>
                        <!-- <div class="d-flex mt-2">
                            <div style="width: 100px; border-top: solid 7px rgb(211, 80, 158);"></div>
                            <div style="width: 100px; border-top: solid 7px rgb(218, 218, 83);"></div>
                            <div style="width: 100px; border-top: solid 7px rgb(64, 188, 64);"></div>
                        </div> -->
                        <p class="mt-3 mobilediscription" style="font-size: 20px; font-family: 'lights' , sans-serif;">We offer wide-format printing solutions,
                            utilizing the latest technology that produces
                            brilliant colors and amazing image quality with
                            scratch, smudge and water resistance.
                            <br>
                            <br>
                            Our wide-format printing solution provides
                            substrates such as
                        </p>
                        <ul class="">
                            <li class=" mobilediscription" style="font-size: 20px; font-family: 'regular' , sans-serif; color: black"><b>• Floor Graphics</b></li><br>
                            <li class=" mobilediscription" style="font-size: 20px; font-family: 'regular' , sans-serif; color: black"><b>• Banners & Posters</b></li><br>
                            <li class=" mobilediscription" style="font-size: 20px; font-family: 'regular' , sans-serif; color: black"><b>• Pull Up Banners</b></li><br>
                            <li class=" mobilediscription" style="font-size: 20px; font-family: 'regular' , sans-serif; color: black"><b>• Magnet Decals</b></li><br>
                            <li class=" mobilediscription" style="font-size: 20px; font-family: 'regular' , sans-serif; color: black"><b>• Window Graphics</b></li><br>
                            <li class=" mobilediscription" style="font-size: 20px; font-family: 'regular' , sans-serif; color: black"><b>• Canvas</b></li><br>
                            <li class=" mobilediscription" style="font-size: 20px; font-family: 'regular' , sans-serif; color: black"><b>• Car & Wall decals</b></li><br>
                            <li class=" mobilediscription" style="font-size: 20px; font-family: 'regular' , sans-serif; color: black"><b>• Frosted Films</b></li><br>
                            <li class=" mobilediscription" style="font-size: 20px; font-family: 'regular' , sans-serif; color: black"><b>• Wall Vinyls</b></li><br>
                            <li class=" mobilediscription" style="font-size: 20px; font-family: 'regular' , sans-serif; color: black"><b>• Textile</b></li>
                        </ul>
                        <img loading="lazy" class="" src="assets/images/Updated_Images/Asset 515.png" alt="">

                    </div>
                    <div class="col-md-3 hide_in_mobile">
                        <img loading="lazy" src="assets/images/demo_16.png" alt="">
                    </div>
                </div>

            </div>

            
    <div>
        <img loading="lazy" class="center_section_img_mobile" src="assets/images/Updated_Images/Asset 3001.png" alt="">
    </div>

            <div class="">
                <div class="row">
                    <div class="col-md-3 hide_in_mobile">
                        <img loading="lazy" src="assets/images/demo_18.png" alt="">
                    </div>
                    <div class="col-md-3 mobie_marketing_strategy">
                        <p class="" style="margin-top: 50px; font-size: 30px; color: black; font-family: 'mermaid';"><b>SIGNS</b></p>
                        <!-- <div class="d-flex mt-2">
                            <div style="width: 100px; border-top: solid 7px rgb(211, 80, 158);"></div>
                            <div style="width: 100px; border-top: solid 7px rgb(218, 218, 83);"></div>
                            <div style="width: 100px; border-top: solid 7px rgb(64, 188, 64);"></div>
                        </div> -->
                        <p class="mt-3  mobilediscription" style="font-size: 20px; font-family: 'lights' , sans-serif;">Most of the business prefer printed signs
                            are attractive and one of the most effective
                            ways to improve brand presence.
                            <br>
                            <br>
                            Printed signs have a huge customizable option
                            with respect to size, shape, style or graphic, and
                            signs with printed, painted, or embossed faces.
                            <br>
                        </p>
                        <ul class="">
                            <li class=" mobilediscription" style="font-size: 20px; font-family: 'regular' , sans-serif; color: black"><b>• Coroplast Signs</b></li><br>
                            <li class=" mobilediscription" style="font-size: 20px; font-family: 'regular' , sans-serif; color: black"><b>• Aluminum Signs</b></li><br>
                            <li class=" mobilediscription" style="font-size: 20px; font-family: 'regular' , sans-serif; color: black"><b>• Posters & Styrene Signs</b></li><br>
                            <li class=" mobilediscription" style="font-size: 20px; font-family: 'regular' , sans-serif; color: black"><b>• Foam Board Signs</b></li><br>
                            <li class=" mobilediscription" style="font-size: 20px; font-family: 'regular' , sans-serif; color: black"><b>• Framed Banners & Standees</b></li>
                        </ul>
                        <img loading="lazy" class="" src="assets/images/Updated_Images/Screenshot 2024-05-09 140401.png" alt="">
                    </div>
                    <div class="col-md-6 hide_in_mobile">
                        <img loading="lazy" src="assets/images/demo_17.png" alt="">
                    </div>
                </div>
            </div>

            
    <div>
        <img loading="lazy" class="center_section_img_mobile" src="assets/images/Updated_Images/Asset 3001.png" alt="">
    </div>

            <div class="">
                <div class="row">
                    <div class="col-md-6 hide_in_mobile">
                        <img loading="lazy" src="assets/images/demo_19.png" alt="">
                    </div>
                    <div class="col-md-3 mobie_marketing_strategy">
                        <p class="" style="margin-top: 50px; font-size: 30px; color: black; font-family: 'mermaid';"><b>PRINT MEDIA</b></p>
                        <!-- <div class="d-flex mt-2">
                            <div style="width: 100px; border-top: solid 7px rgb(211, 80, 158);"></div>
                            <div style="width: 100px; border-top: solid 7px rgb(218, 218, 83);"></div>
                            <div style="width: 100px; border-top: solid 7px rgb(64, 188, 64);"></div>
                        </div> -->
                        <p class="mt-3 mobilediscription" style="font-size: 20px; font-family: 'lights' , sans-serif;">When it comes to business communications
                            services, we can do it all. With a complete range
                            of products and services at your disposal,
                            we can help you get your message out to your
                            customers, employees and vendors a like.
                            <br>
                        </p>
                        <ul class="">
                            <li class=" mobilediscription" style="font-size: 20px; font-family: 'regular' , sans-serif; color: black"><b>• Business Cards</b></li><br>
                            <li class=" mobilediscription" style="font-size: 20px; font-family: 'regular' , sans-serif; color: black"><b>• Letter Heads</b></li><br>
                            <li class=" mobilediscription" style="font-size: 20px; font-family: 'regular' , sans-serif; color: black"><b>• Envelopes</b></li><br>
                            <li class=" mobilediscription" style="font-size: 20px; font-family: 'regular' , sans-serif; color: black"><b>• Post Cards</b></li><br>
                            <li class=" mobilediscription" style="font-size: 20px; font-family: 'regular' , sans-serif; color: black"><b>• Presentation folders</b></li><br>
                            <li class=" mobilediscription" style="font-size: 20px; font-family: 'regular' , sans-serif; color: black"><b>• Flyers</b></li><br>
                            <li class=" mobilediscription" style="font-size: 20px; font-family: 'regular' , sans-serif; color: black"><b>• Brochures</b></li><br>
                            <li class=" mobilediscription" style="font-size: 20px; font-family: 'regular' , sans-serif; color: black"><b>• Bookmarks</b></li><br>
                            <li class=" mobilediscription" style="font-size: 20px; font-family: 'regular' , sans-serif; color: black"><b>• Booklets</b></li><br>
                            <li class=" mobilediscription" style="font-size: 20px; font-family: 'regular' , sans-serif; color: black"><b>• Greeting Cards</b></li><br>
                            <li class=" mobilediscription" style="font-size: 20px; font-family: 'regular' , sans-serif; color: black"><b>• Door Hangers</b></li><br>
                            <li class=" mobilediscription" style="font-size: 20px; font-family: 'regular' , sans-serif; color: black"><b>• Tent Cards</b></li>
                        </ul>
                        <img loading="lazy" src="assets/images/Updated_Images/Asset 141.png" alt="">
                    </div>
                    <div class="col-md-3 hide_in_mobile">
                        <img loading="lazy" src="assets/images/demo_20.png" alt="">
                    </div>
                </div>
            </div>


            <div>
                <img loading="lazy" class="center_section_img_mobile" src="assets/images/Updated_Images/Asset 3001.png" alt="">
            </div>
            



           



            
        </div>
    </div>
</section>




<app-footer></app-footer>