<app-header></app-header>


<!-- <section class="banner overflow-hidden">

    <div style="width: 100%; height: 450px; margin: 0 auto">
        <image-slider1 [slides]="slides"></image-slider1>
    </div>
</section> -->



<section class=" hide_in_mobile about-us pb-6">
    <!-- signageandgraphicdesign -->

    <!-- <div class=""> -->
    <!-- <div class="container"> -->
    <!-- </div> -->

    <!-- <div class=""> -->
    <!-- <div class="home_carousel" > -->
    <image-slider1 [slides]="slides"></image-slider1>
    <!-- </div> -->
    <!-- <div class=""> -->

        
        <div>
            <img loading="lazy" class="center_section" src="assets/images/Updated_Images/Asset 3001.png" alt="">
        </div>


    <!-- backOne_for_service_1 -->
    <div class="container-fluid  graphic_design_service_one">
        <div class="about-image-box">
            <div class="row d-flex align-items-center justify-content-between">
                <!-- <div class="col-lg-2"></div> -->
                <div class="col-lg-6 col-sm-12 mb-4 hide_in_mobile">
                    <!-- <img src="assets/images/image 2 - 2SS - Graphic Design.png" alt=""> -->
                    <img loading="lazy" class="graphic_design_img" src="assets/images/Updated_Images/2167973.png" alt="">

                </div>
                <div class="col-lg-5 col-sm-12 mb-4 mt-3">
                    <p class="m-0" style="font-size: 60px; color: rgb(27, 41, 71); font-family: 'mermaid';"><b>GRAPHIC DESIGN</b></p>
                    <!-- <div class="d-flex  mt-2">
                            <div style="width: 100px; border-top: solid 7px rgb(211, 80, 158);"></div>
                            <div style="width: 100px; border-top: solid 7px rgb(218, 218, 83);"></div>
                            <div style="width: 100px; border-top: solid 7px rgb(64, 188, 64);"></div>
                        </div> -->

                    <div class="about-image  mt-3 box-shadow position-relative">
                        <div class="about-content">
                            <!-- h3 -->
                            <p class="border-b mb-2 pb-2 graphicdesc" style="font-size: 33px; font-family: 'lights' , sans-serif; color: rgb(106, 103, 103);">With a 30 years of expertise in creating a brand
                                language for our proud clients, we expand our
                                business to customers looking for creative
                                input for their start-up brands, growing
                                businesses, established industry & personal use.
                                <br>
                                <br>
                                Microprint offers a talented design team with
                                an innovative and a fresh perspective for your
                                custom brand needs.<br>
                            </p>
                            <ul>
                                <li class="" style="font-size: 30px; font-family: 'regular' , sans-serif; color: black;"><b>• Branding & Visual Identity</b></li><br>
                                <li class="" style="font-size: 30px; font-family: 'regular' , sans-serif; color: black;"><b>• Marketing & Advertising</b></li><br>
                                <li class="" style="font-size: 30px; font-family: 'regular' , sans-serif; color: black;"><b>• Packaging Design</b></li><br>
                                <li class="" style="font-size: 30px; font-family: 'regular' , sans-serif; color: black;"><b>• Publication collaterals</b></li><br>
                                <li class="" style="font-size: 30px; font-family: 'regular' , sans-serif; color: black;"><b>• Environmental Graphics</b></li><br>
                                <li class="" style="font-size: 30px; font-family: 'regular' , sans-serif; color: black;"><b>• Illustrations</b></li>
                            </ul>

                        </div>
                    </div>
                </div>
                <div class="col-lg-2 "></div>
            </div>
        </div>
    </div>


            
    <div id="brandingANDidentity">
        <img loading="lazy" class="center_section" src="assets/images/Updated_Images/Asset 3001.png" alt="">
    </div>

    <!-- </div> -->

    <!-- signageandgraphicdesignForFooter -->
    <div class="container-fluid branding_identity_background  branding">
        <div class="row">

            <div class="col-md-1"></div>
            <div class="col-md-5">
                <p style="margin-top: 10%; font-size: 60px; color: rgb(27, 41, 71); font-family: 'mermaid';"><b>BRANDING &
                        <span>IDENTITY</span></b></p>
                <!-- <div class="d-flex mt-2">
                    <div style="width: 100px; border-top: solid 7px rgb(211, 80, 158);"></div>
                    <div style="width: 100px; border-top: solid 7px rgb(218, 218, 83);"></div>
                    <div style="width: 100px; border-top: solid 7px rgb(64, 188, 64);"></div>
                </div> -->
                <p class="mt-3 " style="font-size: 33px; font-family: 'lights' , sans-serif; color: rgb(106, 103, 103);">We believe that a brand should have longevity
                    and reflect the values of a business. Our design
                    team strives to achieve the avatar of your
                    business that get recognition and remain
                    memorable. Our brand development strategy
                    includes anything that visually reflects your
                    brand -
                </p>
                <ul>
                    <li class="" style="font-size: 30px; font-family: 'regular' , sans-serif; color: black;"><b>• Logo Design</b></li><br>
                    <li class="" style="font-size: 30px; font-family: 'regular' , sans-serif; color: black;"><b>• Brand Guide</b></li><br>
                    <li class="" style="font-size: 30px; font-family: 'regular' , sans-serif; color: black;"><b>• Office Stationary</b></li><br>
                    <li class="" style="font-size: 30px; font-family: 'regular' , sans-serif; color: black;"><b>• Business Cards</b></li><br>
                    <li class="" style="font-size: 30px; font-family: 'regular' , sans-serif; color: black;"><b>• Cutlery Branding</b></li>
                </ul>
                <img class="branding_identity_fsection_newimg" src="assets/images/Updated_Images/4034725.png" alt="">
            </div>
            <div class="col-md-6 hide_in_mobile">
                <div class="d-flex">
                    <img loading="lazy" class="brand_identity_1_img"
                        src="assets/images/Updated_Images/image 8 - 3SBI - Graphic Design.png" alt="">
                    <img loading="lazy" class="sketch_img" src="assets/images/Updated_Images/graphic-designer-sketch-design-logo.jpg"
                        alt="">
                </div>
                <div class="d-flex">
                    <img loading="lazy" class="brand_identity_2_img"
                        src="assets/images/Updated_Images/image 6 - 3SBI - Graphic Design.png" alt="">
                    <img loading="lazy" class="brand_identity_3_img"
                        src="assets/images/Updated_Images/image 3 - 3SBI - Graphic Design.png" alt="">
                    <img loading="lazy" class="brand_identity_4_img"
                        src="assets/images/Updated_Images/image 4 - 3SBI - Graphic Design.png" alt="">
                </div>
                <!-- <img src="assets/images/service_1/demo_2-removebg-preview.png" alt="" width="700"> -->
            </div>
            <!-- <div class="col-md-3 hide_in_mobile">
                <img class="mt-2" src="assets/images/service_1/demo_3-removebg-preview (1) (1).png" width="300" alt="">
                <img src="assets/images/service_1/image 8 - 3SBI - Graphic Design.png" width="500" alt="">
            </div> -->
        </div>

    </div>


          
    <div  id="marketingANDStrategy">
        <img loading="lazy" class="center_section" src="assets/images/Updated_Images/Asset 3001.png" alt="">
    </div>


    <!-- backOne -->
    <div class="">
        <div class="row">
            <div class="col-md-7 hide_in_mobile">
                <div class="d_flex">
                    <div class="colset">
                        <img loading="lazy" class="marketing_strategy_1_img"
                            src="assets/images/Updated_Images/image 2 - 4SMS - Graphic Design.png" alt="">
                        <img loading="lazy" class="marketing_strategy_2_img"
                            src="assets/images/Updated_Images/image 1 - 4SMS - Graphic Design.png" alt="">
                    </div>
                    <div class="colset">
                        <img loading="lazy" class="marketing_strategy_3_img" src="assets/images/Updated_Images/Asset 43.png" alt="">
                        <img loading="lazy" class="marketing_strategy_4_img" src="assets/images/Updated_Images/Asset 45.png" alt="">
                    </div>
                    <!-- <div class="col"></div> -->
                    <img loading="lazy" class="marketing_strategy_5_img"
                        src="assets/images/Updated_Images/image 5 - 4SMS - Graphic Design.png" alt="">
                </div>
                <!-- <img src="assets/images/demo_4.png" alt=""> -->
            </div>
            <div class="col-md-5 marketingdesc">
                <p class="" style="margin-top: 100px; font-size: 60px; color: rgb(27, 41, 71); font-family: 'mermaid';"><b>MARKETING &
                        <span>STRATEGY</span></b></p>
                <!-- <div class="d-flex mt-2">
                    <div style="width: 100px; border-top: solid 7px rgb(211, 80, 158);"></div>
                    <div style="width: 100px; border-top: solid 7px rgb(218, 218, 83);"></div>
                    <div style="width: 100px; border-top: solid 7px rgb(64, 188, 64);"></div>
                </div> -->
                <p class="mt-3 " style="font-size: 33px; font-family: 'lights' , sans-serif; color: rgb(106, 103, 103);">Our Marketing approach follows 4 simple stepsresearch, ideation, design &
                    marketing. Our
                    team has a way to add that hint of emotion,
                    excitement and entertainment that builds an
                    immediate connect with the audience which
                    translates into business values. With marketing
                    on digital platform, we also expand to -
                </p>
                <ul>
                    <li class="" style="font-size: 30px; font-family: 'regular' , sans-serif; color: black;"><b>• Banners | Posters | Billboards</b></li><br>
                    <li class="" style="font-size: 30px; font-family: 'regular' , sans-serif; color: black;"><b>• Magezines & Newspaper Ads.</b></li><br>
                    <li class="" style="font-size: 30px; font-family: 'regular' , sans-serif; color: black;"><b>• Social Media Ads.</b></li><br>
                    <li class="" style="font-size: 30px; font-family: 'regular' , sans-serif; color: black;"><b>• Web Design</b></li><br>
                    <li class="" style="font-size: 30px; font-family: 'regular' , sans-serif; color: black;"><b>• Infographics</b></li><br>
                    <li class="" style="font-size: 30px; font-family: 'regular' , sans-serif; color: black;"><b>• Vehicle Decals</b></li>
                </ul>
                <div class="d_flex">
                    <img loading="lazy" class="marketing_strategy_6_img"
                        src="assets/images/Updated_Images/image 3 - 4SMS - Graphic Design.png" alt="">
                    <img loading="lazy" class="marketing_strategy_7_img"
                        src="assets/images/Updated_Images/image 4 - 4SMS - Graphic Design.png" alt="">
                </div>
            </div>
            <!-- <div class="col-md-4 hide_in_mobile">
                <img src="assets/images/demo_5.png" alt="">
            </div> -->
        </div>
    </div>

          
    <div id="packagingDesign">
        <img loading="lazy" class="center_section" src="assets/images/Updated_Images/Asset 3001.png" alt="">
    </div>


    <!-- signageandgraphicdesignForFooter -->
    <div class="container branding_identity_background  ">
        <div class="row packaging_design">
            <!-- <div class="col-md-6 hide_in_mobile">
                <img src="assets/images/demo_6.png" alt="">
            </div> -->
            <div class="col-md-1"></div>
            <div class="col-md-4">
                <p class=""  style="font-size: 60px; color: rgb(27, 41, 71); font-family: 'mermaid';"><b>PACKAGING
                        <span>DESIGN</span></b></p>
                <!-- <div class="d-flex mt-2">
                    <div style="width: 100px; border-top: solid 7px rgb(211, 80, 158);"></div>
                    <div style="width: 100px; border-top: solid 7px rgb(218, 218, 83);"></div>
                    <div style="width: 100px; border-top: solid 7px rgb(64, 188, 64);"></div>
                </div> -->
                <p class="mt-3 " style="font-size: 33px; font-family: 'lights' , sans-serif; color: rgb(106, 103, 103);">When you look for a packaged deal, packaging
                    comes along. The prettier the box, the more it
                    sells! Our dedicated design team understands
                    the market and upcoming trends to help you
                    sketch your ideas into a new invention.
                    Although we are certainly not just limited to
                    customising boxes -
                </p>
                <ul>
                    <li class="" style="font-size: 30px; font-family: 'regular' , sans-serif; color: black;"><b>• Food & Beverages</b></li><br>
                    <li class="" style="font-size: 30px; font-family: 'regular' , sans-serif; color: black;"><b>• Apparel</b></li><br>
                    <li class="" style="font-size: 30px; font-family: 'regular' , sans-serif; color: black;"><b>• Stickers</b></li><br>
                    <li class="" style="font-size: 30px; font-family: 'regular' , sans-serif; color: black;"><b>• Wrapping Papers</b></li><br>
                    <li class="" style="font-size: 30px; font-family: 'regular' , sans-serif; color: black;"><b>• Textile</b></li>
                </ul>
            </div>
            <div class="col-md-7 hide_in_mobile">
                <div class="d-flex">
                    <img loading="lazy" class="packaging_design_1_img" src="assets/images/Updated_Images/8613415.jpg" alt="">
                    <img loading="lazy" class="packaging_design_2_img" src="assets/images/Updated_Images/Asset 48.png" alt="">
                </div>
                <div class="d-flex">
                    <img loading="lazy" class="packaging_design_3_img" src="assets/images/Updated_Images/8007208.png" alt="">
                    <img loading="lazy" class="packaging_design_4_img"
                        src="assets/images/Updated_Images/image 2 - 5SPD - Graphic Design.png" alt="">
                    <img loading="lazy" class="packaging_design_5_img" src="assets/images/Updated_Images/Asset 49.png" alt="">
                </div>
            </div>
        </div>
    </div>


          
    <div id="publicationEnvironment">
        <img loading="lazy" class="center_section" src="assets/images/Updated_Images/Asset 3001.png" alt="" id="publicationCollateralsID">
    </div>

    <!-- backOne -->
    <div class="container-fluid ">
        <div class="row pub_colla">
            <div class="col-md-7 hide_in_mobile">
                <div class="row">
                    <div class="d-flex">
                        <img loading="lazy" class="publication_collaterals_1_img"
                            src="assets/images/Updated_Images/Screenshot 2024-05-08 102902.png" alt="">
                        <img loading="lazy" class="publication_collaterals_2_img"
                            src="assets/images/Updated_Images/image 6 - 6SPC - Graphic Design.png" alt="">
                        <img loading="lazy" class="publication_collaterals_3_img"
                            src="assets/images/Updated_Images/image 2 - 6SPC - Graphic Design.png" alt="">
                    </div>
                    <div class="d-flex">
                        <img loading="lazy" class="publication_collaterals_4_img" src="assets/images/Updated_Images/aurora.png" alt="">
                        <img loading="lazy" class="publication_collaterals_5_img" src="assets/images/Updated_Images/1234.png" alt="">
                    </div>
                </div>
            </div>
            <div class="col-md-5 publicationdesc">
                <p style="margin-top: 10%; font-size: 60px; color: rgb(27, 41, 71); font-family: 'mermaid';"><b>PUBLICATION
                        <span>COLLATERALS</span></b></p>
                <!-- <div class="d-flex mt-2">
                    <div style="width: 100px; border-top: solid 7px rgb(211, 80, 158);"></div>
                    <div style="width: 100px; border-top: solid 7px rgb(218, 218, 83);"></div>
                    <div style="width: 100px; border-top: solid 7px rgb(64, 188, 64);"></div>
                </div> -->
                <p class="mt-3 "  style="font-size: 33px; font-family: 'lights' , sans-serif; color: rgb(106, 103, 103);">Crafting exceptional printed collaterals that
                    speak the values of your business. We offer an
                    impressive range of quality products which
                    creates a great first impression with your target
                    audience while keeping your on top and raising
                    brand visibility. Some options to begin with -
                </p>
                <ul>
                    <li class="" style="font-size: 30px; font-family: 'regular' , sans-serif; color: black;"><b>• Books</b></li><br>
                    <li class="" style="font-size: 30px; font-family: 'regular' , sans-serif; color: black;"><b>• E-books</b></li><br>
                    <li class="" style="font-size: 30px; font-family: 'regular' , sans-serif; color: black;"><b>• Brochures</b></li><br>
                    <li class="" style="font-size: 30px; font-family: 'regular' , sans-serif; color: black;"><b>• Catalogs</b></li><br>
                    <li class="" style="font-size: 30px; font-family: 'regular' , sans-serif; color: black;"><b>• Menu card</b></li>
                </ul>
                <img loading="lazy" class="publication_collaterals_6_img"
                    src="assets/images/Updated_Images/image 1 - 6SPC - Graphic Design.png" alt="">
            </div>
            <!-- <div class="col-md-5 hide_in_mobile">
                <img src="assets/images/demo_10.png" alt="">
            </div> -->
        </div>
    </div>


          
    <div id="environmentalGraphic">
        <img loading="lazy" class="center_section" src="assets/images/Updated_Images/Asset 3001.png" alt="">
    </div>


    <!-- signageandgraphicdesignForFooter -->
    <div class="container-fluid  branding_identity_background ">
        <div class="row environmental_graphic_part">
            <!-- <div class="col-md-5 hide_in_mobile">
                <img src="assets/images/demo_8.png" alt="">
            </div> -->
            <div class="col-md-7 envir_discr">
                <p style=" font-size: 60px; color: rgb(27, 41, 71); font-family: 'mermaid';"><b>ENVIRONMENTAL<br>
                        <span>GRAPHIC DESIGN</span></b></p>
                <!-- <div class="d-flex mt-2">
                    <div style="width: 100px; border-top: solid 7px rgb(211, 80, 158);"></div>
                    <div style="width: 100px; border-top: solid 7px rgb(218, 218, 83);"></div>
                    <div style="width: 100px; border-top: solid 7px rgb(64, 188, 64);"></div>
                </div> -->
                <p class="mt-3 " style="font-size: 33px; font-family: 'lights' , sans-serif; color: rgb(106, 103, 103);">EGD is a form of art that blends with the space
                    to communicate it’s functionality through
                    colors, typography, patterns, materials, images,
                    or themes. Wayfinding plays an integral part in
                    any environment, especially to someone who is
                    new to the surrounding. We professionally
                    design wayfinding sign program that fits into
                    the surrounding environment by effectively
                    balancing visibility & brand identity.
                    We work with the following EGD programs :
                </p>
                <ul>
                    <li class="" style="font-size: 30px; font-family: 'regular' , sans-serif; color: black;"><b>• Wallfinding Sign & Placemaking</b></li><br>
                    <li class="" style="font-size: 30px; font-family: 'regular' , sans-serif; color: black;"><b>• Wall Arts & Mural</b></li><br>
                    <li class="" style="font-size: 30px; font-family: 'regular' , sans-serif; color: black;"><b>• 3D Art Installation</b></li><br>
                </ul>
            </div>
            <div class="col-md-5 d-flex hide_in_mobile ">
                <img loading="lazy" class="environmental_graphic_1_img"
                    src="assets/images/Updated_Images/image 2 - 7SEGD - Graphic Design.png" alt="">
                <img loading="lazy" class="environmental_graphic_2_img"
                    src="assets/images/Updated_Images/image 3 - 7SEGD - Graphic Design.png" alt="">
                <img loading="lazy" class="environmental_graphic_3_img"
                    src="assets/images/Updated_Images/image 4 - 7SEGD - Graphic Design.png" alt="">
            </div>
        </div>
    </div>

          
    <div id="illustrationID">
        <img loading="lazy" class="center_section" src="assets/images/Updated_Images/Asset 3001.png" alt="">
    </div>



    <!-- backOne -->
    <div class="container-fluid ">
        <div class="row illustration_part">
            <div class="col-md-7 hide_in_mobile">
                <img loading="lazy" class="illustration_1_img" src="assets/images/Updated_Images/image 3 - 8SI - Graphic Design.png"
                    alt="">
                <img loading="lazy" class="illustration_2_img" src="assets/images/Updated_Images/image 4 - 8SI - Graphic Design.png"
                    alt="">
            </div>
            <div class="col-md-5 illustrationdesc">
                <p style="margin-top: 10%; font-size: 60px; color: rgb(27, 41, 71); font-family: 'mermaid';"><b>ILLUSTRATIONS</b></p>
                <!-- <div class="d-flex mt-2">
                    <div style="width: 100px; border-top: solid 7px rgb(211, 80, 158);"></div>
                    <div style="width: 100px; border-top: solid 7px rgb(218, 218, 83);"></div>
                    <div style="width: 100px; border-top: solid 7px rgb(64, 188, 64);"></div>
                </div> -->
                <p class="mt-3 " style="font-size: 33px; font-family: 'lights' , sans-serif; color: rgb(106, 103, 103);">Our creative artists have the tendency to bring
                    life to an empty white canvas. We offer custom
                    and creative illustration services of any style to
                    best suit your business or personal needs. Some
                    areas where we excel at illustrations are :
                </p>
                <ul>
                    <li class="" style="font-size: 30px; font-family: 'regular' , sans-serif; color: black;"><b>• Murals</b></li><br>
                    <li class="" style="font-size: 30px; font-family: 'regular' , sans-serif; color: black;"><b>• Posters</b></li><br>
                    <li class="" style="font-size: 30px; font-family: 'regular' , sans-serif; color: black;"><b>• Greeting Cards</b></li><br>
                    <li class="" style="font-size: 30px; font-family: 'regular' , sans-serif; color: black;"><b>• Wallpapers</b></li><br>
                    <li class="" style="font-size: 30px; font-family: 'regular' , sans-serif; color: black;"><b>• Book Covers</b></li><br>
                </ul>
                <img loading="lazy" class="illustration_3_img" src="assets/images/Updated_Images/image 1 - 8SI - Graphic Design.png"
                    alt="">
            </div>
            <!-- <div class="col-md-5 hide_in_mobile">
                <img src="assets/images/demo_13.png" alt="">
            </div> -->
        </div>
    </div>

          
    <div>
        <img loading="lazy" class="center_section_4" src="assets/images/Updated_Images/Asset 3001.png" alt="">
    </div>
    <!-- </div> -->
    <!-- </div> -->
</section>









<section class="hide_in_pc about-us pb-6">
    <div class="home_carousel">
        <image-slider1 [slides]="slides"></image-slider1>
    </div>
          
    <div>
        <img loading="lazy" class="center_section_4_mobile" src="assets/images/Updated_Images/Asset 3001.png" alt="">
    </div>
    <div class="" style="margin-top: 30px !important;">
        <div class="">

            <div class="container mt-4">
                <div class="about-image-box">
                    <div class="row d-flex align-items-center justify-content-between">
                        <div class="col-lg-2"></div>
                        <div class="col-lg-3 col-sm-12 mb-4 hide_in_mobile">
                            <img loading="lazy" src="assets/images/demo_1.png" alt="">

                        </div>
                        <div class="col-lg-5 col-sm-12 mb-4 mt-3">
                            <p class="m-0 " style="color: black; font-size: 30px;  font-family: 'mermaid';"><b>Graphic Design</b></p>
                            <!-- <div class="d-flex  mt-2">
                                <div style="width: 100px; border-top: solid 7px rgb(211, 80, 158);"></div>
                                <div style="width: 100px; border-top: solid 7px rgb(218, 218, 83);"></div>
                                <div style="width: 100px; border-top: solid 7px rgb(64, 188, 64);"></div>
                            </div> -->

                            <div class="about-image p-3 mt-3 box-shadow position-relative">
                                <div class="about-content">
                                    <p class="border-b mb-2 pb-2  mobilediscription" style="font-size: 20px; font-family: 'lights' , sans-serif;">With a 30 years of expertise in creating a brand
                                        language for our proud clients, we expand our
                                        business to customers looking for creative
                                        input for their start-up brands, growing
                                        businesses, established industry & personal use.
                                        <br>
                                        <br>
                                        Microprint offers a talented design team with
                                        an innovative and a fresh perspective for your
                                        custom brand needs.<br>
                                    </p>
                                    <ul>
                                        <li class=" mobilediscription" style="font-size: 20px; font-family: 'regular' , sans-serif; color: black;"><b>• Branding & Visual Identity</b></li><br>
                                        <li class=" mobilediscription" style="font-size: 20px; font-family: 'regular' , sans-serif; color: black;"><b>• Marketing & Advertising</b></li><br>
                                        <li class=" mobilediscription" style="font-size: 20px; font-family: 'regular' , sans-serif; color: black;"><b>• Packaging Design</b></li><br>
                                        <li class=" mobilediscription" style="font-size: 20px; font-family: 'regular' , sans-serif; color: black;"><b>• Publication collaterals</b></li><br>
                                        <li class=" mobilediscription" style="font-size: 20px; font-family: 'regular' , sans-serif; color: black;"><b>• Environmental Graphics</b></li><br>
                                        <li class=" mobilediscription" style="font-size: 20px; font-family: 'regular' , sans-serif; color: black;"><b>• Illustrations</b></li>
                                    </ul>
                                    <img loading="lazy" class="" src="assets/images/Updated_Images/2167973.png" alt="">

                                </div>
                            </div>
                        </div>
                        <div class="col-lg-2 "></div>
                    </div>
                </div>
            </div>


            <div>
                <img loading="lazy" class="center_section_4" src="assets/images/Updated_Images/Asset 3001.png" alt="">
            </div>


            <div class="container-fluid ">
                <div class="row">
                    <div class="col-md-6 hide_in_mobile">
                        <img loading="lazy" src="assets/images/demo_2.png" alt="">
                    </div>
                    <div class="col-md-4">
                        <p class="" style="margin-top: 20px; color: black; font-size: 30px;  font-family: 'mermaid';"><b>Branding &
                                <span>Identity</span></b></p>
                        <!-- <div class="d-flex mt-2">
                            <div style="width: 100px; border-top: solid 7px rgb(211, 80, 158);"></div>
                            <div style="width: 100px; border-top: solid 7px rgb(218, 218, 83);"></div>
                            <div style="width: 100px; border-top: solid 7px rgb(64, 188, 64);"></div>
                        </div> -->
                        <p class="mt-3  mobilediscription" style="font-size: 20px; font-family: 'lights' , sans-serif;">We believe that a brand should have longevity
                            and reflect the values of a business. Our design
                            team strives to achieve the avatar of your
                            business that get recognition and remain
                            memorable. Our brand development strategy
                            includes anything that visually reflects your
                            brand -
                        </p>
                        <ul>
                            <li class=" mobilediscription" style="font-size: 20px; font-family: 'regular' , sans-serif; color: black;"><b>• Logo Design</b></li><br>
                            <li class=" mobilediscription" style="font-size: 20px; font-family: 'regular' , sans-serif; color: black;"><b>• Brand Guide</b></li><br>
                            <li class=" mobilediscription" style="font-size: 20px; font-family: 'regular' , sans-serif; color: black;"><b>• Office Stationary</b></li><br>
                            <li class=" mobilediscription" style="font-size: 20px; font-family: 'regular' , sans-serif; color: black;"><b>• Business Cards</b></li><br>
                            <li class=" mobilediscription" style="font-size: 20px; font-family: 'regular' , sans-serif; color: black;"><b>• Cutlery Branding</b></li>
                        </ul>
                        <img loading="lazy" class="" src="assets/images/Updated_Images/4034725.png" alt="">

                    </div>
                    <div class="col-md-2 hide_in_mobile">
                        <img loading="lazy" src="assets/images/demo_3.png" alt="">
                    </div>
                </div>

            </div>

            <div>
                <img loading="lazy" class="center_section_4_img_mobile" src="assets/images/Updated_Images/Asset 3001.png" alt="">
            </div>

            <div class="">
                <div class="row">
                    <div class="col-md-3 hide_in_mobile">
                        <img loading="lazy" src="assets/images/demo_4.png" alt="">
                    </div>
                    <div class="col-md-4 mobie_marketing_strategy">
                        <h1 class="" style="margin-top: 70px; color: black; font-size: 30px;  font-family: 'mermaid';"><b>Marketing &
                                <span>Stratagy</span></b></h1>
                        <!-- <div class="d-flex mt-2">
                            <div style="width: 100px; border-top: solid 7px rgb(211, 80, 158);"></div>
                            <div style="width: 100px; border-top: solid 7px rgb(218, 218, 83);"></div>
                            <div style="width: 100px; border-top: solid 7px rgb(64, 188, 64);"></div>
                        </div> -->
                        <p class="mt-3  mobilediscription" style="font-size: 20px; font-family: 'lights' , sans-serif;">Our Marketing approach follows 4 simple stepsresearch, ideation, design &
                            marketing. Our
                            team has a way to add that hint of emotion,
                            excitement and entertainment that builds an
                            immediate connect with the audience which
                            translates into business values. With marketing
                            on digital platform, we also expand to -
                        </p>
                        <ul>
                            <li class=" mobilediscription" style="font-size: 20px; font-family: 'regular' , sans-serif; color: black;"><b>• Banners | Posters | Billboards</b></li><br>
                            <li class=" mobilediscription" style="font-size: 20px; font-family: 'regular' , sans-serif; color: black;"><b>• Magezines & Newspaper Ads.</b></li><br>
                            <li class=" mobilediscription" style="font-size: 20px; font-family: 'regular' , sans-serif; color: black;"><b>• Social Media Ads.</b></li><br>
                            <li class=" mobilediscription" style="font-size: 20px; font-family: 'regular' , sans-serif; color: black;"><b>• Web Design</b></li><br>
                            <li class=" mobilediscription" style="font-size: 20px; font-family: 'regular' , sans-serif; color: black;"><b>• Infographics</b></li><br>
                            <li class=" mobilediscription" style="font-size: 20px; font-family: 'regular' , sans-serif; color: black;"><b>• Vehicle Decals</b></li>
                        </ul>
                        <img loading="lazy" class="" src="assets/images/Updated_Images/Asset 45.png" alt="">

                    </div>
                    <div class="col-md-5 hide_in_mobile">
                        <img loading="lazy" src="assets/images/demo_5.png" alt="">
                    </div>
                </div>
            </div>

            <div>
                <img loading="lazy" class="center_section_4_img_mobile" src="assets/images/Updated_Images/Asset 3001.png" alt="">
            </div>

            <div class="container-fluid ">
                <div class="row">
                    <div class="col-md-5 hide_in_mobile">
                        <img loading="lazy" src="assets/images/demo_6.png" alt="">
                    </div>
                    <div class="col-md-4">
                        <h1 class="" style="margin-top: 70px; color: black; font-size: 30px;  font-family: 'mermaid';"><b>Packaging
                                <span>Design</span></b></h1>
                        <!-- <div class="d-flex mt-2">
                            <div style="width: 100px; border-top: solid 7px rgb(211, 80, 158);"></div>
                            <div style="width: 100px; border-top: solid 7px rgb(218, 218, 83);"></div>
                            <div style="width: 100px; border-top: solid 7px rgb(64, 188, 64);"></div>
                        </div> -->
                        <p class="mt-3  mobilediscription" style="font-size: 20px; font-family: 'lights' , sans-serif;">When you look for a packaged deal, packaging
                            comes along. The prettier the box, the more it
                            sells! Our dedicated design team understands
                            the market and upcoming trends to help you
                            sketch your ideas into a new invention.
                            Although we are certainly not just limited to
                            customising boxes -
                        </p>
                        <ul>
                            <li class=" mobilediscription" style="font-size: 20px; font-family: 'regular' , sans-serif; color: black;"><b>• Food & Beverages</b></li><br>
                            <li class=" mobilediscription" style="font-size: 20px; font-family: 'regular' , sans-serif; color: black;"><b>• Apparel</b></li><br>
                            <li class=" mobilediscription" style="font-size: 20px; font-family: 'regular' , sans-serif; color: black;"><b>• Stickers</b></li><br>
                            <li class=" mobilediscription" style="font-size: 20px; font-family: 'regular' , sans-serif; color: black;"><b>• Wrapping Papers</b></li><br>
                            <li class=" mobilediscription" style="font-size: 20px; font-family: 'regular' , sans-serif; color: black;"><b>• Textile</b></li>
                        </ul>
                        <img loading="lazy" class="" src="assets/images/Updated_Images/image 2 - 5SPD - Graphic Design.png" alt="">
                    </div>
                    <div class="col-md-3 hide_in_mobile">
                        <img loading="lazy" src="assets/images/demo_7.png" alt="">
                    </div>
                </div>
            </div>

            <div>
                <img loading="lazy" class="center_section_4_img_mobile" src="assets/images/Updated_Images/Asset 3001.png" alt="">
            </div>

            <div class="container-fluid publication_collaterals_mobile">
                <div class="row">
                    <div class="col-md-3 hide_in_mobile">
                        <img loading="lazy" src="assets/images/demo_11.png" alt="">
                    </div>
                    <div class="col-md-3">
                        <h1 class="" style="margin-top: 70px; color: black; font-size: 30px;  font-family: 'mermaid';"><b>Publication Collaterals</b></h1>
                        <!-- <div class="d-flex mt-2">
                            <div style="width: 100px; border-top: solid 7px rgb(211, 80, 158);"></div>
                            <div style="width: 100px; border-top: solid 7px rgb(218, 218, 83);"></div>
                            <div style="width: 100px; border-top: solid 7px rgb(64, 188, 64);"></div>
                        </div> -->
                        <p class="mt-3  mobilediscription" style="font-size: 20px; font-family: 'lights' , sans-serif;">Crafting exceptional printed collaterals that
                            speak the values of your business. We offer an
                            impressive range of quality products which
                            creates a great first impression with your target
                            audience while keeping your on top and raising
                            brand visibility. Some options to begin with -
                        </p>
                        <ul>
                            <li class=" mobilediscription" style="font-size: 20px; font-family: 'regular' , sans-serif; color: black;"><b>• Books</b></li><br>
                            <li class=" mobilediscription" style="font-size: 20px; font-family: 'regular' , sans-serif; color: black;"><b>• E-books</b></li><br>
                            <li class=" mobilediscription" style="font-size: 20px; font-family: 'regular' , sans-serif; color: black;"><b>• Brochures</b></li><br>
                            <li class=" mobilediscription" style="font-size: 20px; font-family: 'regular' , sans-serif; color: black;"><b>• Catalogs</b></li><br>
                            <li class=" mobilediscription" style="font-size: 20px; font-family: 'regular' , sans-serif; color: black;"><b>• Menu card</b></li>
                        </ul>
                        <img loading="lazy" class="" src="assets/images/Updated_Images/1234.png" alt="">

                    </div>
                    <div class="col-md-6 hide_in_mobile">
                        <img loading="lazy" src="assets/images/demo_10.png" alt="">
                    </div>
                </div>
            </div>

            <div>
                <img loading="lazy" class="center_section_4_img_mobile" src="assets/images/Updated_Images/Asset 3001.png" alt="">
            </div>


            <div class="container-fluid ">
                <div class="row">
                    <div class="col-md-6 hide_in_mobile">
                        <img src="assets/images/demo_8.png" alt="">
                    </div>
                    <div class="col-md-3">
                        <h1 class="" style="margin-top: 70px; color: black; font-size: 30px;  font-family: 'mermaid';"><b>Environmental
                                <span>Graphic Design</span></b></h1>
                        <!-- <div class="d-flex mt-2">
                            <div style="width: 100px; border-top: solid 7px rgb(211, 80, 158);"></div>
                            <div style="width: 100px; border-top: solid 7px rgb(218, 218, 83);"></div>
                            <div style="width: 100px; border-top: solid 7px rgb(64, 188, 64);"></div>
                        </div> -->
                        <p class="mt-3  mobilediscription" style="font-size: 20px; font-family: 'lights' , sans-serif;">EGD is a form of art that blends with the space
                            to communicate it’s functionality through
                            colors, typography, patterns, materials, images,
                            or themes. Wayfinding plays an integral part in
                            any environment, especially to someone who is
                            new to the surrounding. We professionally
                            design wayfinding sign program that fits into
                            the surrounding environment by effectively
                            balancing visibility & brand identity.
                            We work with the following EGD programs :
                        </p>
                        <ul>
                            <li class=" mobilediscription" style="font-size: 20px; font-family: 'regular' , sans-serif; color: black;"><b>• Wallfinding Sign & Placemaking</b></li><br>
                            <li class=" mobilediscription" style="font-size: 20px; font-family: 'regular' , sans-serif; color: black;"><b>• Wall Arts & Mural</b></li><br>
                            <li class=" mobilediscription" style="font-size: 20px; font-family: 'regular' , sans-serif; color: black;"><b>• 3D Art Installation</b></li><br>
                        </ul>
                        <img loading="lazy" class="environmental_graphic_1_img_mobile"
                            src="assets/images/Updated_Images/image 2 - 7SEGD - Graphic Design.png" alt="">
                    </div>
                    <div class="col-md-3 hide_in_mobile">
                        <img loading="lazy" src="assets/images/demo_9.png" alt="">
                    </div>
                </div>
            </div>


            <div>
                <img loading="lazy" class="center_section_4_img_mobile" src="assets/images/Updated_Images/Asset 3001.png" alt="">
            </div>

            <div class="container-fluid ">
                <div class="row">
                    <div class="col-md-3 hide_in_mobile">
                        <img loading="lazy" src="assets/images/demo_12.png" alt="">
                    </div>
                    <div class="col-md-4">
                        <h1 class="" style="margin-top: 70px; color: black; font-size: 30px;  font-family: 'mermaid';"><b>ILLUSTRATIONS</b></h1>
                        <!-- <div class="d-flex mt-2">
                            <div style="width: 100px; border-top: solid 7px rgb(211, 80, 158);"></div>
                            <div style="width: 100px; border-top: solid 7px rgb(218, 218, 83);"></div>
                            <div style="width: 100px; border-top: solid 7px rgb(64, 188, 64);"></div>
                        </div> -->
                        <p class="mt-3  mobilediscription" style="font-size: 20px; font-family: 'lights' , sans-serif;">Our creative artists have the tendency to bring
                            life to an empty white canvas. We offer custom
                            and creative illustration services of any style to
                            best suit your business or personal needs. Some
                            areas where we excel at illustrations are :
                        </p>
                        <ul>
                            <li class=" mobilediscription" style="font-size: 20px; font-family: 'regular' , sans-serif; color: black;"><b>• Murals</b></li><br>
                            <li class=" mobilediscription" style="font-size: 20px; font-family: 'regular' , sans-serif; color: black;"><b>• Posters</b></li><br>
                            <li class=" mobilediscription" style="font-size: 20px; font-family: 'regular' , sans-serif; color: black;"><b>• Greeting Cards</b></li><br>
                            <li class=" mobilediscription" style="font-size: 20px; font-family: 'regular' , sans-serif; color: black;"><b>• Wallpapers</b></li><br>
                            <li class=" mobilediscription" style="font-size: 20px; font-family: 'regular' , sans-serif; color: black;"><b>• Book Covers</b></li><br>
                        </ul>
                        <img loading="lazy" class="" src="assets/images/Updated_Images/image 4 - 8SI - Graphic Design.png"
                    alt="">
                    </div>
                    <div class="col-md-5 hide_in_mobile">
                        <img loading="lazy" src="assets/images/demo_13.png" alt="">
                    </div>
                </div>
            </div>
            
            <div>
                <img loading="lazy" class="center_section_4_img_mobile" src="assets/images/Updated_Images/Asset 3001.png" alt="">
            </div>
        </div>
    </div>
</section>





<app-footer></app-footer>