<div class="topnav1 mobile_menu1" id="myTopnav1">
    <a class="navbar-brand1" routerLink="">
        <img class="active1" src="assets/images/Microprint Logo.png" alt="image" width="200">
    </a>
    <a routerLink="">HOME</a>
    <a  routerLink="/about">ABOUT</a>
    <!-- <a href="#contact">Contact</a> -->
    <div class="dropdown1">
        <button class="dropbtn1">SERVICES
            <i class="fa fa-caret-down"></i>
        </button>
        <div class="dropdown-content1">
            <a routerLink="/graphic-design">Graphic Design</a>
            <a routerLink="/print-media">Print Media</a>
            <a routerLink="/signage-wayfinding">Signage & Wayfinding</a>
            <a routerLink="/digital-marketing">Digital Marketing</a>
        </div>
    </div>
    <a  routerLink="/contact">CONTACT</a>
    <a href="javascript:void(0);" style="font-size:45px;" class="icon" onclick="myFunction()">&#9776;</a>
</div>
<!-- 
<div style="padding-left:16px">
    <h2>Responsive Topnav with Dropdown</h2>
    <p>Resize the browser window to see how it works.</p>
    <p>Hover over the dropdown button to open the dropdown menu.</p>
</div> -->



<!-- <header class="main_header_area mobile_menu">
        <div class="header_menu" id="header_menu">
            <nav class="navbar navbar-default">
                <div class="container">
                    <div class="navbar-flex d-flex align-items-center justify-content-center  w-100 pb-3 pt-3">
                        <div class="navbar-header " style="margin-right: 100px;">
                            <a class="navbar-brand" routerLink="">
                                <img src="assets/images/Microprint Logo.png" alt="image" width="250">
                            </a>
                        </div>
                        <div class="navbar-collapse1 d-flex align-items-center" id="bs-example-navbar-collapse-1">
                            <ul class="nav navbar-nav" id="responsive-menu">
                                <li class="dropdown submenu active menu">
                                    <a routerLink="" class="dropdown-toggle  font-weight-bold pt-2">Home </a>
                                </li>
                                <li class="menu"><a href="#" >About</a></li>
                                <li class="submenu dropdown menu">
                                    <a  class="dropdown-toggle font-weight-bold" data-toggle="dropdown"
                                        role="button" aria-haspopup="true" aria-expanded="false">Services</a>
                                    <ul class="dropdown-menu">
                                        <li class="submenu dropdown menu">
                                            <a routerLink="/graphic-design" class="dropdown-toggle" data-toggle="dropdown" role="button"
                                                aria-haspopup="true" aria-expanded="false">Graphic Design</a>
                                        </li>
                                        <li class="submenu dropdown menu">
                                            <a routerLink="/print-media" class="dropdown-toggle" data-toggle="dropdown" role="button"
                                                aria-haspopup="true" aria-expanded="false">Print Media</a>
                                        </li>
                                        <li class="submenu dropdown menu">
                                            <a routerLink="/signage-wayfinding" class="dropdown-toggle" data-toggle="dropdown" role="button"
                                                aria-haspopup="true" aria-expanded="false">Signage & Wayfinding</a>
                                        </li>
                                        <li class="submenu dropdown menu">
                                            <a routerLink="/digital-marketing" class="dropdown-toggle" data-toggle="dropdown" role="button"
                                                aria-haspopup="true" aria-expanded="false">Digital Marketing</a>
                                        </li>
                                    </ul>
                                </li>
                                <li class="menu"><a href="#">Connect</a></li>
                            </ul>
                        </div>
                        <div class="register-login d-flex align-items-center">
                        </div>
                        <div id="slicknav-mobile"></div>
                    </div>
                </div>
            </nav>
        </div>
    </header> -->





<header class="main_header_area pc_menu">

    <div class="header_menu" id="header_menu">
        <nav class="navbar navbar-default">
            <div class="container-fluid">
                <!-- w-100 -->
                <div class="navbar-flex d-flex align-items-center justify-content-center  pb-3 pt-3">
                    <div class="navbar-header weblogo" style="margin-right: 550px;">
                        <a class="navbar-brand" routerLink="">
                            <img src="assets/images/Microprint Logo.png" alt="image" width="190">
                        </a>
                    </div>
                    <div class="navbar-collapse1 d-flex align-items-center second_section" id="bs-example-navbar-collapse-1">
                        <ul class="nav navbar-nav" id="responsive-menu">
                            <li class="dropdown submenu active">
                                <!-- font-weight-bold -->
                                <a routerLink="" class="dropdown-toggle  menu"
                                    style="color: black; font-weight: 700; font-size: 25px; font-family: 'bolds',sans-serif !important;">Home
                                </a>
                            </li>

                            <li class="menu"><a routerLink="/about"
                                    style="color: black; font-weight: 700; font-size: 25px; font-family: 'bolds',sans-serif !important;">About</a>
                            </li>
                            <li class="submenu dropdown menu">
                                <a class="dropdown-toggle font-weight-bold" data-toggle="dropdown" role="button"
                                    aria-haspopup="true" aria-expanded="false"
                                    style="color: black; font-weight: 700; font-size: 25px; font-family: 'bolds',sans-serif !important;">Services</a>
                                <ul class="dropdown-menu">
                                    <li class="submenu dropdown">
                                        <a routerLink="/graphic-design" class="dropdown-toggle" data-toggle="dropdown"
                                            role="button" aria-haspopup="true" aria-expanded="false"
                                            style="color: black; font-weight: 700; font-size: 25px; font-family: 'bolds',sans-serif !important;">Graphic
                                            Design</a>
                                    </li>
                                    <li class="submenu dropdown">
                                        <a routerLink="/print-media" class="dropdown-toggle" data-toggle="dropdown"
                                            role="button" aria-haspopup="true" aria-expanded="false"
                                            style="color: black; font-weight: 700; font-size: 25px; font-family: 'bolds',sans-serif !important;">Print
                                            Media</a>

                                    </li>
                                    <li class="submenu dropdown">
                                        <a routerLink="/signage-wayfinding" class="dropdown-toggle"
                                            data-toggle="dropdown" role="button" aria-haspopup="true"
                                            aria-expanded="false"
                                            style="color: black; font-weight: 700; font-size: 25px; font-family: 'bolds',sans-serif !important;">Signage
                                            & Wayfinding</a>

                                    </li>
                                    <li class="submenu dropdown">
                                        <a routerLink="/digital-marketing" class="dropdown-toggle"
                                            data-toggle="dropdown" role="button" aria-haspopup="true"
                                            aria-expanded="false"
                                            style="color: black; font-weight: 700; font-size: 25px; font-family: 'bolds',sans-serif !important;">Digital
                                            Marketing</a>

                                    </li>
                                </ul>
                            </li>


                            <li class="menu"><a routerLink="/contact"
                                    style="color: black; font-weight: 700; font-size: 25px; font-family: 'bolds',sans-serif !important;">Contact</a>
                            </li>
                        </ul>
                    </div>
                    <div class="register-login d-flex align-items-center">
                    </div>
                </div>
            </div>
        </nav>
    </div>
</header>