<app-header></app-header>




<!-- about-us starts -->

<section class=" hide_in_mobile about-us pb-6">
    <!-- signageandgraphicdesign -->
    <!-- <div class=""> -->
    <!-- <div class=""> -->
    <!-- <div  class=""> -->
    <!-- <div class="home_carousel"> -->
    <image-slider7 class="slider" [slides]="slides"></image-slider7>
    <!-- </div> -->


    <div>
        <img class="center_section" loading="lazy" src="assets/images/Updated_Images/Asset 3001.png" alt="">
    </div>

    <!-- home_page_settings -->

    <!-- home_first_image -->
    <div class="container-fluid ourvision pb-6">
        <!-- backOne -->
        <!-- about-image-box  -->
        <!-- image_overlay -->
        <!-- <div class="  "> -->
        <div class="fsection row  align-items-center justify-content-between">
            <div class="col-lg-5  mb-4">
                <img class="microprint_img" loading="lazy" src="assets/images/Updated_Images/Asset 31313131.png" alt="">
            </div>
            <div class="col-lg-6  pt-4">

                <div class="about-content">
                    <h2 class="m-0 what_we_do wwd heading_fo_mobile2 pb-4"
                        style="color: rgb(27, 41, 71); font-family: 'maermaid' !important;"><b>GET IN TOUCH</b></h2>
                    <p class="border-b mb-2 pb-2   microprint_discription"
                        style="font-family: 'regular', sans-serif !important; color: rgb(106, 103, 103);"> We'd love to
                        hear from you! At MicroPrint, we're
                        always ready to help bring your vision to life.
                    </p>

                </div>

                <div class="about-content">
                    <p class="border-b mb-2 pb-2   microprint_discription"
                        style="font-family: 'regular', sans-serif !important; color: rgb(106, 103, 103);"> Get in touch
                        with us today and let’s start creating
                        something amazing together. You can reach us via
                        phone, email, or by visiting our office. We're excited
                        to collaborate and make your ideas a reality.
                    </p>

                </div>
                <div class="">
                    <img class="contImg" src="assets/images/Updated_Images/Screenshot_2024-06-04_115527-removebg-preview.png" alt="">
                </div>
                
                <!-- <div class="about-content22"> -->
                    <!-- <div class="inside_poster">
                            <p class="microprint_discription22"> 9353 50 St NW #15, Edmonton, AB T6B 2L5
                            </p>
                            <p class="" style="font-size: 60px !important; color: white;"> (587) 524-9592
                            </p>
                            <div class="d-flex">
                                <p class="col-md-6 microprint_discription22"> info@microprint.ca
                                </p>
                                <p class="col-md-6 microprint_discription22"> www.microprint.ca</p>
                            </div>
                    </div> -->
                <!-- </div> -->
            </div>


            <div class="col-lg-2 col-sm-12">
            </div>
        </div>
        <!-- </div> -->

    </div>
    <!-- </div> -->
    <!-- 
    <div>
        <img loading="lazy" src="assets/images/Updated_Images/Asset 8.png" alt="" class="first_image_between_section">
    </div> -->



    <div>
        <img class="center_section" loading="lazy" src="assets/images/Updated_Images/Asset 3001.png" alt="">
    </div>



    <div class="container-fluid contactForm ">
        <div class="d-flex justify-content-center align-items-center">
            <p class="text-center"
                style="padding-left: 2%; padding-right: 2%; font-weight: 600;  font-size: 33px;  background-color: rgb(198, 209, 156);">
                CONNECT WITH US</p>
        </div>
        <div class="d-flex justify-content-center align-items-center">
            <p class="h3" style="font-size: 60px; font-weight: 600; font-family: 'regular', sans-serif !important;">
                WRITE
                A MESSAGE</p>
        </div>
        <form [formGroup]="connectwithusForm">
            <div class="row">
                <div class="col-md-6 mb-3 main_name">
                    <input type="text" class="form-control name" id="exampleInputEmail1" [formControl]="connectwithusForm.controls['name']" name="name" placeholder="Full Name">
                </div>
                <div class="col-md-6 mb-3 main_email">
                    <input type="email" class="form-control emailaddress" id="exampleInputPassword1"
                        placeholder="Email Address" [formControl]="connectwithusForm.controls['email']" name="email">
                </div>
            </div>
            <div class="row">
                <div class="col-md-6 mb-3 main_phone">
                    <input type="tel" class="form-control phone" id="exampleInputEmail1" placeholder="Phone" [formControl]="connectwithusForm.controls['phone']" name="phone">
                </div>
                <div class="col-md-6 mb-3 main_subject">
                    <input type="text" class="form-control subject" id="exampleInputPassword1" placeholder="Subject" [formControl]="connectwithusForm.controls['subject']" name="subject">
                </div>
            </div>
            <div class="mb-3 main_msg">
                <textarea class="form-control message" rows="7" id="exampleInputPassword1"
                    placeholder="Write a Message" [formControl]="connectwithusForm.controls['message']" name="message"></textarea>
            </div>

            <div class="d-flex justify-content-center align-items-center main_div_btn">
                <button type="submit" class="btn btn-primary text-center contact_button"  (click)="SendEmail()">SEND MESSAGE</button>
            </div>
        </form>
    </div>



    <div>
        <img loading="lazy" class="center_section" src="assets/images/Updated_Images/Asset 3001.png" alt="">
    </div>

    <!-- </div> -->
    <!-- </div> -->
</section>
<!-- about-us ends -->




















<section class="hide_in_pc about-us pb-6">
    <!-- <div class=""> -->
    <!-- <div class=""> -->
    <div class="home_carousel">
        <image-slider7 [slides]="slides"></image-slider7>
    </div>

    

    <div>
        <img loading="lazy" class="center_section_mobile_img_11222" src="assets/images/Updated_Images/Asset 3001.png" alt="">
    </div>

    <div>
        <img loading="lazy" class="center_section_mobile_about" src="assets/images/Updated_Images/Asset 31313131.png"
            alt="">
    </div>
    <div class="container-fluid  pt-5 pb-4" style="margin-top: 0%;">
        <div class="about-image-box mt-6 ">
            <div class="row d-flex align-items-center justify-content-between">
                <div class="col-lg-3 col-sm-12 mb-4">
                    <div class="about-content">
                        <p class="border-b mb-2 pb-2  mobilediscription "
                            style="font-size: 20px; font-family: 'regular', sans-serif !important;">We'd love to
                            hear from you! At MicroPrint, we're
                            always ready to help bring your vision to life.<br>
                            <br>
                            Get in touch
                        with us today and let’s start creating
                        something amazing together. You can reach us via
                        phone, email, or by visiting our office. We're excited
                        to collaborate and make your ideas a reality.
                        </p>
                    </div>
                    <div class="">
                        <!-- contImg_mob -->
                        <img class="" src="assets/images/Updated_Images/Screenshot_2024-06-04_115527-removebg-preview.png" alt="">
                    </div>
                </div>

                <div class="col-lg-3 col-sm-12 mb-4">
                    <img loading="lazy" class="hide_in_mobile" style="margin-top: -550px; "
                        src="assets/images/image 2 - 2SS - Home Page.png" alt="" width="300">
                </div>
            </div>
        </div>

    </div>

    <div>
        <img loading="lazy" class="center_section_mobile_img" src="assets/images/Updated_Images/Asset 3001.png" alt="">
    </div>
    <!-- assets/images/Updated_Images/Asset 525252.png -->
<!--     
    <div>
        <img loading="lazy" class="center_section_mobile_img" src="assets/images/Updated_Images/Asset 3001.png" alt="">
    </div> -->




    <div class="container-fluid contactForm ">
        <div class="d-flex justify-content-center align-items-center">
            <p class="text-center"
                style="padding-left: 2%; padding-right: 2%; font-weight: 600;  font-size: 23px;  background-color: rgb(198, 209, 156);">
                CONECT WITH US</p>
        </div>
        <div class="d-flex justify-content-center align-items-center">
            <p class="h3" style="font-size: 30px; font-weight: 600; font-family: 'regular', sans-serif !important;">
                WRITE
                A MESSAGE</p>
        </div>
        <form>
            <div class="row">
                <div class="col-md-6 mb-3 main_name">
                    <input type="text" class="form-control name" id="exampleInputEmail1" placeholder="Full Name">
                </div>
                <div class="col-md-6 mb-3 main_email">
                    <input type="email" class="form-control emailaddress" id="exampleInputPassword1"
                        placeholder="Email Address">
                </div>
            </div>
            <div class="row">
                <div class="col-md-6 mb-3 main_phone">
                    <input type="tel" class="form-control phone" id="exampleInputEmail1" placeholder="Phone">
                </div>
                <div class="col-md-6 mb-3 main_subject">
                    <input type="text" class="form-control subject" id="exampleInputPassword1" placeholder="Subject">
                </div>
            </div>
            <div class="mb-3 main_msg">
                <textarea class="form-control message" rows="7" id="exampleInputPassword1"
                    placeholder="Write a Message"></textarea>
            </div>

            <div class="d-flex justify-content-center align-items-center main_div_btn">
                <button type="submit" class="btn btn-primary text-center contact_button">SEND MESSAGE</button>
            </div>
        </form>
    </div>

    <div>
        <img loading="lazy" class="center_section_mobile_img" src="assets/images/Updated_Images/Asset 3001.png" alt="">
    </div>






    <!-- </div> -->
</section>



<app-footer></app-footer>