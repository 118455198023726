import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ImageSlider1Component } from './components/imageSlider1/imageSlider1.component';

@NgModule({
  imports: [CommonModule],
  exports: [ImageSlider1Component],
  declarations: [ImageSlider1Component],
})
export class ImageSlider1Module {}
