<app-header></app-header>

<!-- <section class="banner overflow-hidden">
    <div class="home_carousel" style="width: 100%; height: 450px; margin: 0 auto">
        <image-slider1 [slides]="slides"></image-slider1>
    </div>
</section> -->


<section class=" about-us pb-6 hide_in_mobile">
    <!-- signageandgraphicdesign -->

    <!-- <div class="image_overlay "> -->
    <!-- graphic_design -->
    <!-- <div class="home_carousel"> -->
    <image-slider2 [slides]="slides"></image-slider2>
    <!-- </div> -->
    <!-- <div class=""> -->


    <div>
        <img loading="lazy" class="center_section" src="assets/images/Updated_Images/Asset 3001.png" alt="">
    </div>

    <!-- backOne_for_service_1 -->
    <div class="container-fluid  mt-8 signage_design_service_one">
        <div class="about-image-box pt-8">
            <div class="row d-flex align-items-center justify-content-between">
                <!-- <div class="col-lg-2"></div> -->
                <div class="col-lg-6 ">
                    <img loading="lazy" class="signange_wayfinding_img"
                        src="assets/images/Updated_Images/image 2 - 2SS - Signage & Wayfinding.png" alt="">
                    <!-- <img src="assets/images/demo_14.png" width="550" alt=""> -->

                </div>
                <div class="col-lg-6 mb-4 mt-3 sig_wayfin_descr">
                    <p class="m-0 "
                        style="color: rgb(67, 66, 66); font-size: 60px; color: rgb(27, 41, 71); font-family: 'mermaid';">
                        <b>SIGNAGE & WAYFINDING</b>
                    </p>
                    <!-- <div class="d-flex  mt-2">
                                <div style="width: 100px; border-top: solid 7px rgb(211, 80, 158);"></div>
                                <div style="width: 100px; border-top: solid 7px rgb(218, 218, 83);"></div>
                                <div style="width: 100px; border-top: solid 7px rgb(64, 188, 64);"></div>
                            </div> -->

                    <div class="about-image p-3 mt-3 position-relative">
                        <div class="about-content">
                            <p class=" mb-2 pb-2 " style="font-size: 33px; font-family: 'lights' , sans-serif; color: rgb(106, 103, 103);">As one
                                of the leading manufacturer of Signage
                                & Wayfinding signs; we offer a wide range of
                                products that contribute to the safety and
                                enhancement of our urban space
                                <br>
                                <br>
                                At Microprint, we’re reshaping urban navigation
                                by offering different types of wayfinding signs
                                designed for your needs.
                                <br>
                                <br>
                                With a 30 years of experience, we understand
                                the evolving urban landscape and adapt our
                                processes to deliver effective, innovative
                                wayfinding signage.<br>
                            </p>
                        </div>
                    </div>
                </div>
                <!-- <div class="col-lg-1"></div> -->
            </div>
            <div class="row" style="margin-top: 5%;">
                <div class="col-md-7">
                    <img loading="lazy" class="signange_wayfinding_1_img" src="assets/images/Updated_Images/Asset 2.png" alt="">
                </div>
                <div class="col-md-5">
                    <img loading="lazy" class="signange_wayfinding_2_img" src="assets/images/Updated_Images/Asset 4.png" alt="">
                </div>
            </div>
        </div>
    </div>


    <div id="channellettersID">
        <img loading="lazy" class="center_section_1" src="assets/images/Updated_Images/Asset 3001.png" alt="">
    </div>

    <div class="container-fluid signageandgraphicdesignForFooter chennal_letters_main">
        <div class="row chnl_letters">
            <div class="col-md-5 mb-4 chnl_descr">
                <p
                    style=" font-size: 60px; color: rgb(27, 41, 71); font-family: 'mermaid';">
                    <b>CHANNEL LETTERS</b>
                </p>
                <p class="mt-3 " style="font-size: 33px; font-family: 'lights' , sans-serif; color: rgb(106, 103, 103);">These are 3-dimensional
                    letters, numbers or
                    characters usually 3-6 inches deep made out of
                    aluminum or stainless steel.
                    <br>
                    <br>
                    Channel Letters are often used as a brand’s
                    primary outdoor sign. The depth of this sign
                    literally makes the brand stand-out!
                    <br>
                    <br>
                    PRO - Channel Letter signs can be made<br>
                    front, back or halo lit.
                </p>
            </div>
            <div class="col-md-7 d-flex">
                <img loading="lazy" class="channel_letters_1_img" src="assets/images/Updated_Images/Asset 333.png" alt="">
                <!-- <img class="channel_letters_2_img"
                    src="assets/images/Updated_Images/image 7 - 3SCL - Signage & Wayfinding.png" alt="">
                <img class="channel_letters_3_img"
                    src="assets/images/Updated_Images/image 3 - 3SCL - Signage & Wayfinding.png" alt="">
                <img class="channel_letters_4_img"
                    src="assets/images/Updated_Images/image 4 - 3SCL - Signage & Wayfinding.png" alt="">
                <img class="channel_letters_5_img"
                    src="assets/images/Updated_Images/image 5 - 3SCL - Signage & Wayfinding.png" alt=""> -->
            </div>
        </div>

    </div>


    <div id="boxsignsID">
        <img loading="lazy" class="center_section_2" src="assets/images/Updated_Images/Asset 3001.png" alt="">
    </div>


    <!-- backOne -->
    <div class="container-fluid box_signs_background  box_signs_main">
        <div class="row box_signs">
            <div class="col-md-7 d-flex">
                <img loading="lazy" class="box_signs_1_img"
                    src="assets/images/Updated_Images/image 5 - 4SBS - Signage & Wayfinding.png" alt="">
                <img loading="lazy" class="box_signs_2_img" src="assets/images/Updated_Images/Asset 5.png" alt="">
            </div>
            <div class="col-md-5 box_signs_descr">
                <p
                    style=" font-size: 60px; color: rgb(27, 41, 71); font-family: 'mermaid';">
                    <b>BOX SIGNS</b>
                </p>
                <!-- <div class="d-flex mt-2">
                    <div style="width: 100px; border-top: solid 7px rgb(211, 80, 158);"></div>
                    <div style="width: 100px; border-top: solid 7px rgb(218, 218, 83);"></div>
                    <div style="width: 100px; border-top: solid 7px rgb(64, 188, 64);"></div>
                </div> -->
                <p class="mt-3 " style="font-size: 33px; font-family: 'lights' , sans-serif; color: rgb(106, 103, 103);">Box signs are usually
                    lightbox signages which
                    are attractive and one of the most effective
                    ways to improve your business presence.
                    <br>
                    <br>
                    Box Signs have a huge customizable option
                    with respect to size, shape, style or graphic, and
                    signs with printed, painted, or embossed faces.
                    <br>
                    <br>
                    PRO - Box Signs increases brand visibility
                    not only at night but in the day as well.
                    <br>
                </p>
            </div>
            <!-- <div class="col-md-5">
                <img src="assets/images/service_2/image 6 - 3SCL - Signage & Wayfinding_2.png" alt="">
            </div> -->
        </div>
    </div>

    <div id="pylonsignsID">
        <img loading="lazy" class="center_section_3" src="assets/images/Updated_Images/Asset 3001.png" alt="">
    </div>

    <!-- signageandgraphicdesignForFooter -->
    <div class="pylon_signs_background  pylon_signs">
        <div class="row">
            <!-- <div class="col-md-6">
                <img src="assets/images/demo_19.png" alt="">
            </div> -->
            <div class="col-md-5 pylon_decr">
                <p
                    style=" font-size: 60px; color: rgb(27, 41, 71); font-family: 'mermaid';">
                    <b>PYLON SIGNS</b>
                </p>
                <!-- <div class="d-flex mt-2">
                    <div style="width: 100px; border-top: solid 7px rgb(211, 80, 158);"></div>
                    <div style="width: 100px; border-top: solid 7px rgb(218, 218, 83);"></div>
                    <div style="width: 100px; border-top: solid 7px rgb(64, 188, 64);"></div>
                </div> -->
                <p class="mt-3 " style="font-size: 33px; font-family: 'lights' , sans-serif; color: rgb(106, 103, 103);">Get your brand noticed
                    from a great distance
                    to passing traffic. With this heavy duty pylon
                    sign installed in your premise be assured of
                    getting greater benefits-
                </p>
                <ul>
                    <li class="" style="font-size: 30px; font-family: 'regular' , sans-serif; color: black;"><b>• High
                            visibility</b></li><br>
                    <li class="" style="font-size: 30px; font-family: 'regular' , sans-serif; color: black;"><b>• Looks
                            Attractive</b></li><br>
                    <li class="" style="font-size: 30px; font-family: 'regular' , sans-serif; color: black;"><b>• Easier to be
                            Found</b></li><br>
                    <li class="" style="font-size: 30px; font-family: 'regular' , sans-serif; color: black;"><b>• Brand
                            Awareness</b></li>
                </ul>
                <p class="mt-3 " style="font-size: 33px;">PRO - A pylon sign literally makes your brand
                    Stand-Out!</p>

            </div>
            <div class="col-md-7 d-flex">
                <img loading="lazy" class="pylonsigns_1_img" src="assets/images/Updated_Images/Asset 666.png" alt="">
                <!-- <img class="pylonsigns_2_img"
                    src="assets/images/Updated_Images/image 2 - 5SPS - Signage & Wayfinding-overlay.png" alt="">
                <div class="col_img">
                    <img class="pylonsigns_3_img"
                        src="assets/images/Updated_Images/image 6 - 5SPS - Signage & Wayfinding.png" alt="">
                    <img class="pylonsigns_4_img"
                        src="assets/images/Updated_Images/image 7 - 5SPS - Signage & Wayfinding.png" alt="">
                </div> -->
            </div>
        </div>
    </div>


    <div id="neonsignsID">
        <img loading="lazy" class="center_section_3" src="assets/images/Updated_Images/Asset 3001.png" alt="">
    </div>

    <!-- backOne -->
    <div class=" ">
        <div class="row">
            <div class="col-md-7">
                <img loading="lazy" class="neon_signs_1_img" src="assets/images/Updated_Images/Screenshot 2024-05-09 082504.png"
                    alt="">
            </div>
            <div class="col-md-5 neon_signs_descr">
                <p
                    style="font-size: 60px; color: rgb(27, 41, 71); font-family: 'mermaid';">
                    <b>NEON SIGNS</b>
                </p>
                <!-- <div class="d-flex mt-2">
                    <div style="width: 100px; border-top: solid 7px rgb(211, 80, 158);"></div>
                    <div style="width: 100px; border-top: solid 7px rgb(218, 218, 83);"></div>
                    <div style="width: 100px; border-top: solid 7px rgb(64, 188, 64);"></div>
                </div> -->
                <p class="mt-3 " style="font-size: 31px; font-family: 'lights' , sans-serif; color: rgb(106, 103, 103);">One of the classics that
                    brands still consider
                    modern & futuristic are Neon Signs. The bright
                    glow emitted from a neon sign easily grabs the
                    attention of the passing audience.
                    <br>
                    Date back all the way to the 1920s, these signs
                    are still popular majorly among the restaurants
                    & bars. May it be a simple ‘Open / Close’ sign or
                    just some artistic wall decor piece to elevate
                    your space. Indoor neon signs have a great
                    impact in darker spaces.
                    <br>
                    <br>
                    PRO - Neon Signs never run out of style!
                    <br>
                </p>
            </div>
            <!-- <div class="col-md-5">
                <img src="assets/images/demo_21.png" alt="">
            </div> -->
        </div>
    </div>

    <div id="litsignsID">
        <img loading="lazy" class="center_section_5" src="assets/images/Updated_Images/Asset 3001.png" alt="">
    </div>


    <!-- signageandgraphicdesignForFooter -->
    <div class="pylon_signs_background lit_signs">
        <div class="row">
            <!-- <div class="col-md-6">
                <img src="assets/images/demo_23.png" alt="">
            </div> -->
            <div class="col-md-5 lit_signs_descr">
                <p
                    style="margin-top: 10%; font-size: 60px; color: rgb(27, 41, 71); font-family: 'mermaid';">
                    <b>LIT SIGNS</b>
                </p>
                <!-- <div class="d-flex mt-2">
                    <div style="width: 100px; border-top: solid 7px rgb(211, 80, 158);"></div>
                    <div style="width: 100px; border-top: solid 7px rgb(218, 218, 83);"></div>
                    <div style="width: 100px; border-top: solid 7px rgb(64, 188, 64);"></div>
                </div> -->
                <p class="mt-3 " style="font-size: 31px; font-family: 'lights' , sans-serif; color: rgb(106, 103, 103);">LED Lit Signs are
                    extensively used for
                    commercial signage. But now-a-days beyond
                    commerce, LED signs are getting popular
                    among community groups, hospitality, retail,
                    government buildings, residence, institutes,
                    memorials & public parks.
                    <br>
                    With our amalgamation of a Front, Back or
                    a Halo Lit LED Sign, you can create a visual
                    impact on your brand.
                    <br>
                    <br>
                    Pro - LED Lit Signs being robust and durable,
                    are pocket-friendly and eco-friendly!
                    <br>
                </p>
                <!-- <ul>
                    <li class="h4"><b>Wallfinding Sign & Placemaking</b></li><br>
                    <li class="h4"><b>Wall Arts & Mural</b></li><br>
                    <li class="h4"><b>3D Art Installation</b></li><br>
                </ul> -->
            </div>
            <div class="col-md-7">
                <img loading="lazy" class="lit_signs_1_img" src="assets/images/Updated_Images/Asset 888.png" alt="">
            </div>
        </div>
    </div>

    <div id="wayfindingsignsID">
        <img loading="lazy" class="center_section_6" src="assets/images/Updated_Images/Asset 3001.png" alt="">
    </div>

    <!-- backOne -->
    <div class="">
        <div class="row">
            <div class="col-md-7">
                <img loading="lazy" class="wayfinding_signs_1_img" src="assets/images/Updated_Images/Screenshot 2024-05-09 091144.png"
                    alt="">
            </div>
            <div class="col-md-5">
                <img loading="lazy" class="wayfinding_signs_2_img"
                    src="assets/images/Updated_Images/Screenshot_2024-05-09_091651-removebg-preview.png" alt="">
                <p style=" font-size: 60px; color: rgb(27, 41, 71); font-family: 'mermaid';"><b>WAYFINDING
                        SIGNS</b>
                </p>
                <!-- <div class="d-flex mt-2">
                    <div style="width: 100px; border-top: solid 7px rgb(211, 80, 158);"></div>
                    <div style="width: 100px; border-top: solid 7px rgb(218, 218, 83);"></div>
                    <div style="width: 100px; border-top: solid 7px rgb(64, 188, 64);"></div>
                </div> -->
                <p class="mt-3 signagedesc" style="font-size: 33px; font-family: 'lights' , sans-serif; color: rgb(106, 103, 103);">A simplified visual
                    approach to guide individual
                    from a source to a destination. The process of
                    designing a wayfinding program involves a
                    comprehensive process that is user-focussed
                    and requires a combination of technical know
                    <br>
                    -ledge and innovative skills
                    <br>
                    We extensively work with 4 types of Wayfinding
                    <br>
                </p>
                <ul>
                    <li class="" style="font-size: 30px; font-family: 'regular' , sans-serif; color: black;"><b>• Directional</b>
                    </li><br>
                    <li class="" style="font-size: 30px; font-family: 'regular' , sans-serif; color: black;"><b>• Identification
                            (Destination)</b></li><br>
                    <li class="" style="font-size: 30px; font-family: 'regular' , sans-serif; color: black;"><b>• Information (You
                            are here)</b></li><br>
                    <li class="" style="font-size: 30px; font-family: 'regular' , sans-serif; color: black;"><b>• Regulatory
                            (Mandatory / warning)</b></li><br>
                </ul>
                <img loading="lazy" class="wayfinding_signs_4_img"
                    src="assets/images/Updated_Images/Screenshot_2024-05-09_091651-removebg-preview.png" alt="">
                <img loading="lazy" class="wayfinding_signs_3_img"
                    src="assets/images/Updated_Images/image 10 - 8SWS - Signage & Wayfinding.png" alt="">
            </div>
            <!-- <div class="col-md-5">
                <img src="assets/images/demo_25.png" alt="">
            </div> -->
        </div>
    </div>


    <div>
        <img loading="lazy" class="center_section_7" src="assets/images/Updated_Images/Asset 3001.png" alt="">
    </div>

    <!-- </div> -->
    <!-- </div> -->
</section>












<section class="hide_in_pc about-us pb-6">
    <div class="home_carousel">
        <image-slider2 [slides]="slides"></image-slider2>
    </div>

    <div>
        <img loading="lazy" class="center_section_mobile" src="assets/images/Updated_Images/Asset 3001.png" alt="">
    </div>

    <div class="" style="margin-top: 5% !important;">
        <div class="">

            <div class="container mt-4">
                <div class="about-image-box">
                    <div class="row d-flex align-items-center justify-content-between">
                        <div class="col-lg-7 col-sm-12 mb-4 hide_in_mobile">
                            <img loading="lazy" src="assets/images/demo_14.png" alt="">
                        </div>
                        <div class="col-lg-4 col-sm-12  mt-3">
                            <p class="m-0"
                                style="color: black; font-size: 30px;  font-family: 'mermaid';">
                                <b>Signage & Wayfinding</b>
                            </p>
                            <!-- <div class="d-flex  mt-2">
                                <div style="width: 100px; border-top: solid 7px rgb(211, 80, 158);"></div>
                                <div style="width: 100px; border-top: solid 7px rgb(218, 218, 83);"></div>
                                <div style="width: 100px; border-top: solid 7px rgb(64, 188, 64);"></div>
                            </div> -->

                            <div class="about-image p-3 mt-3 box-shadow position-relative">
                                <div class="about-content">
                                    <p class="border-b mb-2 pb-2 mobilediscription"
                                        style="font-size: 20px; font-family: 'lights' , sans-serif;">As one of the
                                        leading
                                        manufacturer of Signage
                                        & Wayfinding signs; we offer a wide range of
                                        products that contribute to the safety and
                                        enhancement of our urban space
                                        <br>
                                        <br>
                                        At Microprint, we’re reshaping urban navigation
                                        by offering different types of wayfinding signs
                                        designed for your needs.
                                        <br>
                                        <br>
                                        With a 30 years of experience, we understand
                                        the evolving urban landscape and adapt our
                                        processes to deliver effective, innovative
                                        wayfinding signage.<br>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-1"></div>
                    </div>
                    <div class="row" style="margin-top: 5%;">
                        <div class="col-md-7">
                            <img loading="lazy" class="signange_wayfinding_1_img" src="assets/images/Updated_Images/Asset 2.png"
                                alt="">
                        </div>
                        <div class="col-md-5">
                            <img loading="lazy" class="signange_wayfinding_2_img" src="assets/images/Updated_Images/Asset 4.png"
                                alt="">
                        </div>
                    </div>
                </div>
            </div>

            <div>
                <img loading="lazy" class="center_section_7" src="assets/images/Updated_Images/Asset 3001.png" alt="">
            </div>
            <div class="">
                <div class="row">
                    <div class="col-md-6 hide_in_mobile">
                        <img loading="lazy" src="assets/images/demo_15.png" alt="">
                    </div>
                    <div class="col-md-3 mobie_marketing_strategy">
                        <p class=""
                            style="margin-top: 0px; color: black; font-size: 30px;  font-family: 'mermaid';">
                            <b>Channel <span>Letters</span></b>
                        </p>
                        <!-- <div class="d-flex mt-2">
                            <div style="width: 100px; border-top: solid 7px rgb(211, 80, 158);"></div>
                            <div style="width: 100px; border-top: solid 7px rgb(218, 218, 83);"></div>
                            <div style="width: 100px; border-top: solid 7px rgb(64, 188, 64);"></div>
                        </div> -->
                        <p class="mt-3 mobilediscription" style="font-size: 20px; font-family: 'lights' , sans-serif;">
                            These are 3-dimensional letters, numbers or
                            characters usually 3-6 inches deep made out of
                            aluminum or stainless steel.
                            <br>
                            <br>
                            Channel Letters are often used as a brand’s
                            primary outdoor sign. The depth of this sign
                            literally makes the brand stand-out!
                            <br>
                            PRO - Channel Letter signs can be made<br>
                            front, back or halo lit.
                        </p>
                        <img loading="lazy" class="channel_letters_3_img_mobile"
                            src="assets/images/Updated_Images/image 3 - 3SCL - Signage & Wayfinding.png" alt="">
                    </div>
                    <div class="col-md-3 hide_in_mobile">
                        <img loading="lazy" src="assets/images/demo_16.png" alt="">
                    </div>
                </div>

            </div>

            <div>
                <img loading="lazy" class="center_section_mobile_1" src="assets/images/Updated_Images/Asset 3001.png" alt="">
            </div>

            <div class="">
                <div class="row">
                    <div class="col-md-3 hide_in_mobile">
                        <img loading="lazy" src="assets/images/demo_18.png" alt="">
                    </div>
                    <div class="col-md-3 mobie_marketing_strategy">
                        <p class=""
                            style="margin-top: 50px; color: black; font-size: 30px;  font-family: 'mermaid';">
                            <b>BOX
                                <span>SIGNS</span></b>
                        </p>
                        <!-- <div class="d-flex mt-2">
                            <div style="width: 100px; border-top: solid 7px rgb(211, 80, 158);"></div>
                            <div style="width: 100px; border-top: solid 7px rgb(218, 218, 83);"></div>
                            <div style="width: 100px; border-top: solid 7px rgb(64, 188, 64);"></div>
                        </div> -->
                        <p class="mt-3 mobilediscription" style="font-size: 20px; font-family: 'lights' , sans-serif;">
                            Box signs are usually lightbox signages which
                            are attractive and one of the most effective
                            ways to improve your business presence.
                            <br>
                            <br>
                            Box Signs have a huge customizable option
                            with respect to size, shape, style or graphic, and
                            signs with printed, painted, or embossed faces.
                            <br>
                            <br>
                            PRO - Box Signs increases brand visibility
                            not only at night but in the day as well.
                            <br>
                        </p>
                        <img loading="lazy" class="box_signs_2_img_mobile" src="assets/images/Updated_Images/Asset 5.png" alt="">

                    </div>
                    <div class="col-md-6 hide_in_mobile">
                        <img loading="lazy" src="assets/images/demo_17.png" alt="">
                    </div>
                </div>
            </div>


            <div>
                <img loading="lazy" class="center_section_mobile_1" src="assets/images/Updated_Images/Asset 3001.png" alt="">
            </div>
            <div class="">
                <div class="row">
                    <div class="col-md-6 hide_in_mobile">
                        <img loading="lazy" src="assets/images/demo_19.png" alt="">
                    </div>
                    <div class="col-md-3 mobie_marketing_strategy">
                        <p class=""
                            style="margin-top: 50px;  color: black; font-size: 30px; font-family: 'mermaid';">
                            <b>PYLON
                                <span>SIGNS</span></b>
                        </p>
                        <!-- <div class="d-flex mt-2">
                            <div style="width: 100px; border-top: solid 7px rgb(211, 80, 158);"></div>
                            <div style="width: 100px; border-top: solid 7px rgb(218, 218, 83);"></div>
                            <div style="width: 100px; border-top: solid 7px rgb(64, 188, 64);"></div>
                        </div> -->
                        <p class="mt-3 mobilediscription" style="font-size: 20px; font-family: 'lights' , sans-serif;">
                            Get your brand noticed from a great distance
                            to passing traffic. With this heavy duty pylon
                            sign installed in your premise be assured of
                            getting greater benefits-
                        </p>
                        <ul>
                            <li class=" mobilediscription"
                                style="font-size: 20px; font-family: 'regular' , sans-serif; color: black;"><b>• High visibility</b>
                            </li><br>
                            <li class=" mobilediscription"
                                style="font-size: 20px; font-family: 'regular' , sans-serif; color: black;"><b>• Looks Attractive</b>
                            </li><br>
                            <li class=" mobilediscription"
                                style="font-size: 20px; font-family: 'regular' , sans-serif; color: black;"><b>• Easier to be
                                    Found</b></li><br>
                            <li class=" mobilediscription"
                                style="font-size: 20px; font-family: 'regular' , sans-serif; color: black;"><b>• Brand Awareness</b>
                            </li>
                        </ul>
                        <p class="mt-3  mobilediscription" style="font-size: 20px; font-family: 'lights' , sans-serif;">
                            PRO - A pylon sign literally makes your brand
                            Stand-Out!</p>
                        <img loading="lazy" class="pylonsigns_1_img_mobile" src="assets/images/Updated_Images/Asset 6.png" alt="">

                    </div>
                    <div class="col-md-3 hide_in_mobile">
                        <img loading="lazy" src="assets/images/demo_20.png" alt="">
                    </div>
                </div>
            </div>


            <div>
                <img loading="lazy" class="center_section_7" src="assets/images/Updated_Images/Asset 3001.png" alt="">
            </div>
            <div class="">
                <div class="row">
                    <div class="col-md-3 hide_in_mobile">
                        <img loading="lazy" src="assets/images/demo_22.png" alt="">
                    </div>
                    <div class="col-md-3 mobie_marketing_strategy">
                        <p class=""
                            style="margin-top: 50px;  color: black; font-size: 30px; font-family: 'mermaid';">
                            <b>NEON
                                <span>SIGNS</span></b>
                        </p>
                        <!-- <div class="d-flex mt-2">
                            <div style="width: 100px; border-top: solid 7px rgb(211, 80, 158);"></div>
                            <div style="width: 100px; border-top: solid 7px rgb(218, 218, 83);"></div>
                            <div style="width: 100px; border-top: solid 7px rgb(64, 188, 64);"></div>
                        </div> -->
                        <p class="mt-3 mobilediscription" style="font-size: 20px; font-family: 'lights' , sans-serif;">
                            One of the classics that brands still consider
                            modern & futuristic are Neon Signs. The bright
                            glow emitted from a neon sign easily grabs the
                            attention of the passing audience.
                            <br>
                            Date back all the way to the 1920s, these signs
                            are still popular majorly among the restaurants
                            & bars. May it be a simple ‘Open / Close’ sign or
                            just some artistic wall decor piece to elevate
                            your space. Indoor neon signs have a great
                            impact in darker spaces.
                            <br>
                            <br>
                            PRO - Neon Signs never run out of style!
                            <br>
                        </p>
                        <img loading="lazy" class="neon_signs_1_img_mobile"
                            src="assets/images/Updated_Images/Screenshot 2024-05-09 082504.png" alt="">
                    </div>
                    <div class="col-md-6 hide_in_mobile">
                        <img loading="lazy" src="assets/images/demo_21.png" alt="">
                    </div>
                </div>
            </div>


            <div>
                <img loading="lazy" class="center_section_mobile_12" src="assets/images/Updated_Images/Asset 3001.png" alt="">
            </div>

            <div class="">
                <div class="row">
                    <div class="col-md-6 hide_in_mobile">
                        <img loading="lazy" src="assets/images/demo_23.png" alt="">
                    </div>
                    <div class="col-md-3 mobie_marketing_strategy">
                        <p class=""
                            style="margin-top: 50px;  color: black; font-size: 30px; font-family: 'mermaid';">
                            <b>LIT
                                <span>SIGNS</span></b>
                        </p>
                        <!-- <div class="d-flex mt-2">
                            <div style="width: 100px; border-top: solid 7px rgb(211, 80, 158);"></div>
                            <div style="width: 100px; border-top: solid 7px rgb(218, 218, 83);"></div>
                            <div style="width: 100px; border-top: solid 7px rgb(64, 188, 64);"></div>
                        </div> -->
                        <p class="mt-3 mobilediscription" style="font-size: 20px; font-family: 'lights' , sans-serif;">
                            LED Lit Signs are extensively used for
                            commercial signage. But now-a-days beyond
                            commerce, LED signs are getting popular
                            among community groups, hospitality, retail,
                            government buildings, residence, institutes,
                            memorials & public parks.
                            <br>
                            With our amalgamation of a Front, Back or
                            a Halo Lit LED Sign, you can create a visual
                            impact on your brand.
                            <br>
                            <br>
                            Pro - LED Lit Signs being robust and durable,
                            are pocket-friendly and eco-friendly!
                            <br>
                        </p>
                        <!-- <ul>
                            <li class="h5">Wallfinding Sign & Placemaking</li><br>
                            <li class="h5">Wall Arts & Mural</li><br>
                            <li class="h5">3D Art Installation</li><br>
                        </ul> -->
                    </div>
                    <div class="col-md-3 hide_in_mobile">
                        <img loading="lazy" src="assets/images/demo_24.png" alt="">
                    </div>
                </div>
            </div>


            <div>
                <img loading="lazy" class="center_section_mobile_1" src="assets/images/Updated_Images/Asset 3001.png" alt="">
            </div>

            <div class="">
                <div class="row">
                    <div class="col-md-3 hide_in_mobile">
                        <img loading="lazy" src="assets/images/demo_26.png" alt="">
                    </div>
                    <div class="col-md-3 mobie_marketing_strategy">
                        <p class=""
                            style="margin-top: 50px;  color: black; font-size: 30px; font-family: 'mermaid';">
                            <b>WAYFINDING SIGNS</b>
                        </p>
                        <!-- <div class="d-flex mt-2">
                            <div style="width: 100px; border-top: solid 7px rgb(211, 80, 158);"></div>
                            <div style="width: 100px; border-top: solid 7px rgb(218, 218, 83);"></div>
                            <div style="width: 100px; border-top: solid 7px rgb(64, 188, 64);"></div>
                        </div> -->
                        <p class="mt-3  mobilediscription" style="font-size: 20px; font-family: 'lights' , sans-serif;">
                            A simplified visual approach to guide individual
                            from a source to a destination. The process of
                            designing a wayfinding program involves a
                            comprehensive process that is user-focussed
                            and requires a combination of technical know
                            <br>
                            -ledge and innovative skills
                            <br>
                            We extensively work with 4 types of Wayfinding
                            <br>
                        </p>
                        <ul>
                            <li class=" mobilediscription"
                                style="font-size: 20px; font-family: 'regular' , sans-serif; color: black;"><b>• Directional</b></li>
                            <br>
                            <li class=" mobilediscription"
                                style="font-size: 20px; font-family: 'regular' , sans-serif; color: black;"><b>• Identification
                                    (Destination)</b></li><br>
                            <li class=" mobilediscription"
                                style="font-size: 20px; font-family: 'regular' , sans-serif; color: black;"><b>• Information (You are
                                    here)</b></li><br>
                            <li class=" mobilediscription"
                                style="font-size: 20px; font-family: 'regular' , sans-serif; color: black;"><b>• Regulatory (Mandatory
                                    / warning)</b></li><br>
                        </ul>
                        <img loading="lazy" class="wayfinding_signs_4_img"
                            src="assets/images/Updated_Images/Screenshot_2024-05-09_091651-removebg-preview.png" alt="">
                        <img loading="lazy" class="wayfinding_signs_3_img"
                            src="assets/images/Updated_Images/image 10 - 8SWS - Signage & Wayfinding.png" alt="">
                    </div>
                    <div class="col-md-6 hide_in_mobile">
                        <img loading="lazy" src="assets/images/demo_25.png" alt="">
                    </div>
                </div>
            </div>


            <div>
                <img loading="lazy" class="center_section_mobile_12" src="assets/images/Updated_Images/Asset 3001.png" alt="">
            </div>


        </div>
    </div>
</section>




<app-footer></app-footer>